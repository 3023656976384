import React, { useRef, useMemo } from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { useJsApiLoader } from '@react-google-maps/api';
import Logger from '../../../../Utils/Logger';
import PropTypes from 'prop-types';

const AddressAutoComplete = ({
  value,
  onAddressChange,
  setSelectedAddress,
}) => {
  const inputRef = useRef(null);
  const libraries = useMemo(() => ['places'], []);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
    version: '3.31',
  });

  const handleChangeInput = (e) => {
    onAddressChange(e.target.value);
  };

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      const addressComponents = place.address_components;
      let city = '';
      let state = '';
      let postalCode = '';

      for (const component of addressComponents) {
        const types = component.types;
        if (types.includes('locality')) {
          city = component.long_name;
        } else if (types.includes('administrative_area_level_1')) {
          state = component.short_name;
        } else if (types.includes('postal_code')) {
          postalCode = component.long_name;
        }
      }

      const addressObject = {
        address_1: place?.name,
        address_2: null,
        zip_code: postalCode,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      setSelectedAddress(addressObject);
      // Construct the complete address
      Logger.log(` ${city}, ${state} ${postalCode}, ${addressObject}`);
      const completeAddress = `${place.formatted_address}`;
      // Update the input field value
      onAddressChange(completeAddress);
    }
  };

  return (
    <>
      {loadError && <p>{loadError}</p>}
      {isLoaded && (
        <StandaloneSearchBox
          onLoad={(ref) => (inputRef.current = ref)}
          onPlacesChanged={handlePlaceChanged}
        >
          <input
            type="text"
            placeholder="Type to search address*"
            className="form-control"
            onChange={handleChangeInput}
            value={value}
            required
          />
        </StandaloneSearchBox>
      )}
    </>
  );
};

AddressAutoComplete.propTypes = {
  value: PropTypes.string,
  onAddressChange: PropTypes.func,
  setSelectedAddress: PropTypes.func,
};
export default AddressAutoComplete;
