import React from 'react';
import { Navigate } from 'react-router-dom';
import { getLoginSeller } from '../LocalStorage';
import PropTypes from 'prop-types';

const PrivateRoute = ({ element }) => {
  const user = getLoginSeller();
  return user ? element : <Navigate to="/" />;
};

PrivateRoute.propTypes = {
  element: PropTypes.any,
};

export default PrivateRoute;
