import React, { useEffect, useState } from 'react';
import {
  SOCIAL_LINK_ENDPOINT,
  SOCIAL_LINK_SETUP_ENDPOINT,
} from '../../../../../../../Utils/Endpoints';
import axiosInstance from '../../../../../../../Utils/axiosInstance';
import Logger from '../../../../../../../Utils/Logger';
import PropTypes from 'prop-types';
import Toast from '../../../../../../../Utils/Toast';

const SocialMedia = ({ ownerClass, ownerId }) => {
  const [socialMediaList, setSocialMediaList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLink, setIsLoadingLink] = useState(false);
  const [generalError, setGeneralError] = useState('');

  useEffect(() => {
    if (ownerClass && ownerId) {
      fetchSocialMedia();
    }
  }, [ownerClass, ownerId]);

  const fetchSocialMedia = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        SOCIAL_LINK_ENDPOINT + `?owner_class=${ownerClass}&owner_id=${ownerId}`
      );
      if (response.data.code === 200) {
        setSocialMediaList(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (index, event) => {
    const updatedList = [...socialMediaList];
    // Update the specific item's url
    updatedList[index].url = event.target.value;

    // Update the state with the new list
    setSocialMediaList(updatedList);
  };

  // Function to create an object from socialMediaList
  const createLinksObject = () => {
    return socialMediaList.reduce((acc, item) => {
      acc[item.type] = item.url;
      return acc;
    }, {});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError('');
    setIsLoadingLink(true);
    try {
      const requestObject = {
        owner_class: ownerClass,
        owner_id: ownerId,
        links: createLinksObject(),
      };
      const response = await axiosInstance.post(
        SOCIAL_LINK_SETUP_ENDPOINT,
        requestObject
      );
      if (response.data.code !== 200) {
        setGeneralError(response.data.message);
        return;
      }
      Toast.success(response.data.message, {
        autoClose: 2000,
      });
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoadingLink(false);
    }
  };

  return (
    <>
      <div className="row g-3">
        {!isLoading &&
          socialMediaList?.map((item, index) => (
            <div className="col-12" key={index}>
              <label className="form-label">{item?.typeName}</label>
              <div className="user-url">
                <input
                  type="text"
                  className="form-control"
                  name={item?.typeName}
                  autoComplete="off"
                  value={item.url}
                  onChange={(event) => handleChange(index, event)}
                />
              </div>
            </div>
          ))}
        {/* <div className="col-12">
          <label className="form-label">Facebook Page</label>
          <div className="user-url">
            <input
              type="text"
              className="form-control"
              name="userName"
              autoComplete="off"
            />
            <div className="user-action-icon">
              <button className="btn btn-outline-info px-2" type="button">
                <ion-icon name="add-outline"></ion-icon>
                Save
              </button>
              <button className="btn btn-outline-warning px-2" type="button">
                <ion-icon name="pencil-outline"></ion-icon>
                Edit
              </button>
              <button className="btn btn-outline-danger px-2 det">
                <ion-icon name="trash-outline"></ion-icon>
              </button>
            </div>
          </div>
          <p className="mb-0 text-secondary full-url">A full URL</p>
        </div>
        <div className="col-12">
          <label className="form-label">Instagram Profile</label>
          <div className="user-url">
            <input
              type="text"
              className="form-control"
              name="userName"
              autoComplete="off"
            />
            <div className="user-action-icon">
              <button className="btn btn-outline-info px-2" type="button">
                <ion-icon name="add-outline"></ion-icon>
                Save
              </button>
              <button className="btn btn-outline-warning px-2" type="button">
                <ion-icon name="pencil-outline"></ion-icon>
                Edit
              </button>
              <button className="btn btn-outline-danger px-2 det">
                <ion-icon name="trash-outline"></ion-icon>
              </button>
            </div>
          </div>

          <p className="mb-0 text-secondary full-url">Your account username</p>
        </div>
        <div className="col-12">
          <label className="form-label">TikTok Profile</label>
          <div className="user-url">
            <input
              type="text"
              className="form-control"
              name="userName"
              autoComplete="off"
            />

            <div className="user-action-icon">
              <button className="btn btn-outline-info px-2" type="button">
                <ion-icon name="add-outline"></ion-icon>
                Save
              </button>
              <button className="btn btn-outline-warning px-2" type="button">
                <ion-icon name="pencil-outline"></ion-icon>
                Edit
              </button>
              <button className="btn btn-outline-danger px-2 det">
                <ion-icon name="trash-outline"></ion-icon>
              </button>
            </div>
          </div>
          <p className="mb-0 text-secondary full-url">Your account username</p>
        </div>
        <div className="col-12">
          <label className="form-label">Apple Music</label>
          <div className="user-url">
            <input
              type="text"
              className="form-control"
              name="userName"
              autoComplete="off"
            />

            <div className="user-action-icon">
              <button className="btn btn-outline-info px-2" type="button">
                <ion-icon name="add-outline"></ion-icon>
                Save
              </button>
              <button className="btn btn-outline-warning px-2" type="button">
                <ion-icon name="pencil-outline"></ion-icon>
                Edit
              </button>
              <button className="btn btn-outline-danger px-2 det">
                <ion-icon name="trash-outline"></ion-icon>
              </button>
            </div>
          </div>
          <p className="mb-0 text-secondary full-url">A full URL</p>
        </div>
        <div className="col-12">
          <label className="form-label">Spotify</label>
          <div className="user-url">
            <input
              type="text"
              className="form-control"
              name="userName"
              autoComplete="off"
            />

            <div className="user-action-icon">
              <button className="btn btn-outline-info px-2" type="button">
                <ion-icon name="add-outline"></ion-icon>
                Save
              </button>
              <button className="btn btn-outline-warning px-2" type="button">
                <ion-icon name="pencil-outline"></ion-icon>
                Edit
              </button>
              <button className="btn btn-outline-danger px-2 det">
                <ion-icon name="trash-outline"></ion-icon>
              </button>
            </div>
          </div>
          <p className="mb-0 text-secondary full-url">A full URL</p>
        </div>
        <div className="col-12">
          <label className="form-label">Youtube Page</label>

          <div className="user-url">
            <input
              type="text"
              className="form-control"
              name="userName"
              autoComplete="off"
            />
            <div className="user-action-icon">
              <button className="btn btn-outline-info px-2" type="button">
                <ion-icon name="add-outline"></ion-icon>
                Save
              </button>
              <button className="btn btn-outline-warning px-2" type="button">
                <ion-icon name="pencil-outline"></ion-icon>
                Edit
              </button>
              <button className="btn btn-outline-danger px-2 det">
                <ion-icon name="trash-outline"></ion-icon>
              </button>
            </div>
          </div>
          <p className="mb-0 text-secondary full-url">A full URL</p>
        </div>
        <div className="col-12">
          <label className="form-label">Soundcloud Page</label>
          <div className="user-url">
            <input
              type="text"
              className="form-control"
              name="userName"
              autoComplete="off"
            />

            <div className="user-action-icon">
              <button className="btn btn-outline-info px-2" type="button">
                <ion-icon name="add-outline"></ion-icon>
                Save
              </button>
              <button className="btn btn-outline-warning px-2" type="button">
                <ion-icon name="pencil-outline"></ion-icon>
                Edit
              </button>
              <button className="btn btn-outline-danger px-2 det">
                <ion-icon name="trash-outline"></ion-icon>
              </button>
            </div>
          </div>
          <p className="mb-0 text-secondary full-url">A full URL</p>
        </div>
        <h5 className="mb-0">Soundcloud</h5>
        <div className="col-12">
          <p className="full-url text-secondary">
            You can add more than one Soundcloud player
          </p>
          <div className="user-url">
            <input
              type="text"
              className="form-control int"
              name="userName"
              autoComplete="off"
            />

            <div className="user-action-icon">
              <button className="btn btn-outline-info px-2" type="button">
                <ion-icon name="add-outline"></ion-icon>
                Save
              </button>
              <button className="btn btn-outline-warning px-2" type="button">
                <ion-icon name="pencil-outline"></ion-icon>
                Edit
              </button>
              <button className="btn btn-outline-danger px-2 det">
                <ion-icon name="trash-outline"></ion-icon>
              </button>
            </div>
          </div>
          <p className="mb-0 text-secondary full-url">
            Paste here the embedding code copied from Soundcloud
          </p>
        </div> */}
        <div className="col-12">
          <div className="d-flex align-items-center gap-3">
            <button
              className="btn btn-outline-warning px-4"
              type="button"
              onClick={handleSubmit}
              disabled={isLoadingLink}
            >
              {isLoadingLink ? 'Loading...' : 'Save'}
            </button>
          </div>
          {generalError && (
            <div className="error text-center">{generalError}</div>
          )}
        </div>
      </div>
    </>
  );
};

SocialMedia.propTypes = {
  ownerClass: PropTypes.string,
  ownerId: PropTypes.string,
};
export default SocialMedia;
