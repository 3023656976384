import React, { useEffect, useState } from 'react';
import Layout from '../Components/Layout';
import { Link } from 'react-router-dom';
import { EVENTS_ENDPOINT } from '../../../../Utils/Endpoints';
import axiosInstance from '../../../../Utils/axiosInstance';
import Logger from '../../../../Utils/Logger';
import moment from 'moment/moment';
import NotFoundPage from '../Components/NotFoundPage';
import Pagination from '../Components/Pagination';
import { saveObject } from '../../../../Utils/LocalStorage';

const Listing = () => {
  const [upcomingEventList, setUpcomingEventList] = useState([]);
  const [pastEventList, setPastEventList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Upcoming Pagination
  const [itemsPerPageUpcoming] = useState(10);
  const [totalItemsUpcoming, setTotalItemsUpcoming] = useState(0);
  const [currentPageUpcoming, setCurrentPageUpcoming] = useState(1);

  const handlePageChangeUpcoming = (pageNumber) => {
    setCurrentPageUpcoming(pageNumber);
  };

  // Past Pagination
  const [itemsPerPagePast] = useState(1);
  const [totalItemsPast, setTotalItemsPast] = useState(0);
  const [currentPagePast, setCurrentPagePast] = useState(1);

  const handlePageChangePast = (pageNumber) => {
    setCurrentPagePast(pageNumber);
  };

  useEffect(() => {
    fetchUpcomingEvent();
  }, [currentPageUpcoming]);

  useEffect(() => {
    fetchPastEvent();
  }, [currentPagePast]);

  const fetchUpcomingEvent = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        EVENTS_ENDPOINT +
          `?event_start_from=${moment().unix()}&page=${currentPageUpcoming}&per_page=${itemsPerPageUpcoming}`
      );
      if (response.data.code === 200) {
        setTotalItemsUpcoming(response.data.data.total);
        setUpcomingEventList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPastEvent = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        EVENTS_ENDPOINT +
          `?event_start_to=${moment().unix()}&page=${currentPagePast}&per_page=${itemsPerPagePast}`
      );
      if (response.data.code === 200) {
        setTotalItemsPast(response.data.data.total);
        setPastEventList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEvent = (item) => {
    saveObject('selectedOrganization', item);
  };

  return (
    <Layout>
      <div className="page-breadcrumb d-flex align-items-center mb-3">
        <div className="font-22">My Events</div>
        <Link to="/create-events" className="btn btn-outline-info ms-auto">
          <i className="bx bx-plus"></i>Create Event
        </Link>
      </div>
      <div className="card p-3">
        <h6>Upcoming Events</h6>
        <hr />
        {!isLoading && upcomingEventList?.length > 0 && (
          <>
            <div className="row row-cols-2 row-cols-md-3 row-cols-xl-4 g-3">
              {upcomingEventList?.map((item, index) => (
                <div className="col" key={index}>
                  <div className="card bg-secondary-dark mb-0 border-1 hover-border-info h-100">
                    <img
                      src={
                        item?.banner?.fullUrl
                          ? item?.banner?.fullUrl
                          : '../images/thumbnail.png'
                      }
                      className="card-img-top empty"
                      alt="..."
                    />
                    <div className="card-body border-bottom h-100">
                      <h5 className="card-title">{item?.name}</h5>
                      <p className="card-text">
                        {item?.location?.addressString
                          ? item?.location?.addressString
                          : '136 2nd Ave, New York, NY 10003, USA'}
                      </p>
                    </div>
                    <div className="card-body border-top">
                      <Link
                        to="/edit-events-user"
                        state={{ event: item }}
                        onClick={() => handleEvent(item?.organization)}
                        className="btn btn-sm btn-outline-warning me-2"
                      >
                        Edit Detail
                      </Link>
                      <span className="float-md-end badge bg-info text-dark d-none">
                        Live
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {totalItemsUpcoming > 10 && (
              <Pagination
                itemsPerPage={itemsPerPageUpcoming}
                totalItems={totalItemsUpcoming}
                currentPage={currentPageUpcoming}
                onPageChange={handlePageChangeUpcoming}
              />
            )}
          </>
        )}
        {!isLoading && upcomingEventList?.length === 0 && <NotFoundPage />}
      </div>
      <div className="card p-3">
        <h6>Past Events</h6>
        <hr />
        {!isLoading && pastEventList?.length > 0 && (
          <>
            <div className="row row-cols-2 row-cols-md-3 row-cols-xl-4 g-3">
              {pastEventList?.map((item, index) => (
                <div className="col" key={index}>
                  <div className="card bg-secondary-dark mb-0 border-1 hover-border-info h-100">
                    <img
                      src={
                        item?.banner?.fullUrl
                          ? item?.banner?.fullUrl
                          : 'https://media.chillzapp.com/uploads/pictures/5766945044b1d8deff93cb06193adff8.png'
                      }
                      className="card-img-top empty"
                      alt="..."
                    />
                    <div className="card-body border-bottom h-100">
                      <h5 className="card-title">{item?.name}</h5>
                      <p className="card-text">
                        {item?.location?.addressString
                          ? item?.location?.addressString
                          : '136 2nd Ave, New York, NY 10003, USA'}
                      </p>
                    </div>
                    <div className="card-body border-top">
                      <Link
                        to="/edit-events-user"
                        state={{ event: item }}
                        onClick={() => handleEvent(item?.organization)}
                        className="btn btn-sm btn-outline-warning me-2"
                      >
                        Edit Detail
                      </Link>
                      <span className="float-md-end badge bg-info text-dark d-none">
                        Live
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {totalItemsPast > 10 && (
              <Pagination
                itemsPerPage={itemsPerPagePast}
                totalItems={totalItemsPast}
                currentPage={currentPagePast}
                onPageChange={handlePageChangePast}
              />
            )}
          </>
        )}
        {!isLoading && pastEventList?.length === 0 && <NotFoundPage />}
      </div>
    </Layout>
  );
};

export default Listing;
