import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from '../../../../../../../Utils/axiosInstance';
import Logger from '../../../../../../../Utils/Logger';
import {
  TAG_ENDPOINT,
  TAG_LIST_ENDPOINT,
} from '../../../../../../../Utils/Endpoints';
import Toast from '../../../../../../../Utils/Toast';

const ChooseTag = ({ ownerClass, ownerId }) => {
  const [search, setSearch] = useState('');
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTag, setIsLoadingTag] = useState(false);
  const [generalError, setGeneralError] = useState('');

  useEffect(() => {
    if ((ownerClass, ownerId)) {
      fetchTagList();
    }
  }, [ownerClass, ownerId, search]);

  const fetchTagList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        TAG_LIST_ENDPOINT +
          `?order_by=name&order=ASC&search=${search}&owner_class=${ownerClass}&owner_id=${ownerId}`
      );
      if (response.data.code === 200) {
        setTags(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = (id) => {
    setTags((prevTags) =>
      prevTags.map((tag) =>
        tag.id === id
          ? { ...tag, is_assigned: tag.is_assigned === 0 ? 1 : 0 }
          : tag
      )
    );
  };

  // Filter tags where is_assigned is 1
  const getAssignedTagIds = (tags) => {
    return tags.filter((tag) => tag.is_assigned === 1).map((tag) => tag.id);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError('');
    setIsLoadingTag(true);
    try {
      const requestObject = {
        owner_class: ownerClass,
        owner_id: ownerId,
        tag_ids: getAssignedTagIds(tags),
      };
      const response = await axiosInstance.post(TAG_ENDPOINT, requestObject);
      if (response.data.code !== 200) {
        setGeneralError(response.data.message);
        return;
      }
      Toast.success(response.data.message, {
        autoClose: 2000,
      });
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoadingTag(false);
    }
  };

  return (
    <>
      <input
        type="search"
        placeholder="Search Tag"
        className="form-control mb-3"
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        value={search}
      />
      {!isLoading && tags?.length > 0 && (
        <div className="checkbox-container">
          {tags?.map((tag) => (
            <label key={tag.id} htmlFor={tag.id} className="checkbox-button">
              <input
                type="checkbox"
                id={tag.id}
                name={tag.name}
                checked={tag.is_assigned}
                onChange={() => handleCheckboxChange(tag.id)}
              />
              <span className="label-text">{tag.name}</span>
            </label>
          ))}
        </div>
      )}
      <div className="checkbox-container d-none">
        <label className="checkbox-button">
          <input type="checkbox" />
          <span className="label-text">Afternoon</span>
        </label>
        <label className="checkbox-button">
          <input type="checkbox" />
          <span className="label-text">All Ages</span>
        </label>
        <label className="checkbox-button">
          <input type="checkbox" />
          <span className="label-text">Art</span>
        </label>
        <label className="checkbox-button">
          <input type="checkbox" />
          <span className="label-text">Beach</span>
        </label>
        <label className="checkbox-button">
          <input type="checkbox" />
          <span className="label-text">Dance</span>
        </label>
        <label className="checkbox-button">
          <input type="checkbox" />
          <span className="label-text">Afternoon</span>
        </label>
        <label className="checkbox-button">
          <input type="checkbox" />
          <span className="label-text">All Ages</span>
        </label>
        <label className="checkbox-button">
          <input type="checkbox" />
          <span className="label-text">Art</span>
        </label>
        <label className="checkbox-button">
          <input type="checkbox" />
          <span className="label-text">Beach</span>
        </label>
        <label className="checkbox-button">
          <input type="checkbox" />
          <span className="label-text">Dance</span>
        </label>
      </div>
      {tags?.length > 0 && (
        <div className="col-12 mt-4">
          <div className="d-flex align-items-center gap-3">
            <button
              className="btn btn-outline-warning px-4"
              type="button"
              onClick={handleSubmit}
              disabled={isLoadingTag}
            >
              {isLoadingTag ? 'Loading...' : 'Save'}
            </button>
          </div>
          {generalError && (
            <div className="error text-center">{generalError}</div>
          )}
        </div>
      )}
    </>
  );
};

ChooseTag.propTypes = {
  ownerClass: PropTypes.string,
  ownerId: PropTypes.string,
};

export default ChooseTag;
