import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from '../../../../Utils/axiosInstance';
import Toast from '../../../../Utils/Toast';
import { ORGANIZATION_ENDPOINT } from '../../../../Utils/Endpoints';

const DeleteModal = ({ selectedData, handleUpdateList }) => {
  const [generalError, setGeneralError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setGeneralError('');
      let endpoint = '';
      let object = {};
      switch (selectedData.type) {
        case 'organization':
          endpoint = ORGANIZATION_ENDPOINT + '/' + selectedData.id;
          break;
        default:
          throw new Error(`Unsupported type: ${selectedData.type}`);
      }

      const response = await axiosInstance.deleteWithBody(endpoint, object);

      if (response.status === 204) {
        const modalClose =
          document.getElementsByClassName('close-delete-modal');
        for (let i = 0; i < modalClose.length; i++) {
          modalClose[i].click();
        }
        handleUpdateList();
        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="col-12">
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: 'none' }}
          data-backdrop="static"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Are you sure?
                </h5>
                <button
                  type="button"
                  className="btn-close close-delete-modal"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                Do you really want to delete this record? This process cannot be
                undone.
              </div>
              {generalError && <p className="error m-2">{generalError}</p>}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={handleDelete}
                  disabled={isLoading}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-outline-warning"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

DeleteModal.propTypes = {
  selectedData: PropTypes.object,
  handleUpdateList: PropTypes.func,
};

export default DeleteModal;
