import CreateEventForm from '../../../Events/CreateEventForm';
import OrgLayout from '../Components/OrgLayout';
import React from 'react';

const EventsCreate = () => {
  return (
    <>
      <OrgLayout>
        <CreateEventForm />
      </OrgLayout>
    </>
  );
};

export default EventsCreate;
