import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AddTicketType from './AddTicketType';
import axiosInstance from '../../../../../../../Utils/axiosInstance';
import Logger from '../../../../../../../Utils/Logger';
import {
  EVENT_TICKET_ENDPOINT,
  TICKET_ENDPOINT,
} from '../../../../../../../Utils/Endpoints';
import Toast from '../../../../../../../Utils/Toast';
import NotFoundPage from '../../../../Components/NotFoundPage';

const TicketType = ({ event }) => {
  const [isAdd, setIsAdd] = useState(false);
  const [ticketList, setTicketList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTicekt, setSelectedTicekt] = useState({});

  const handleAdd = () => {
    setIsAdd(!isAdd);
    setSelectedTicekt({});
  };

  const handleEdit = (item) => {
    setSelectedTicekt(item);
    setIsAdd(!isAdd);
  };

  useEffect(() => {
    fetchTicket();
  }, []);

  const fetchTicket = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        EVENT_TICKET_ENDPOINT + `${event.id}/tickets`
      );
      if (response.data.code === 200) {
        setTicketList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (e, id) => {
    e.preventDefault(id);
    try {
      setIsLoading(true);
      const response = await axiosInstance.delete(TICKET_ENDPOINT + id);
      if (response.status === 204) {
        await fetchTicket();
        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
      }
    } catch (error) {
      Toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="">
        <div className="">
          <div className="page-breadcrumb d-flex align-items-center mb-3">
            <div className="font-22">Ticket Type</div>
            {!isAdd && (
              <a className="btn btn-outline-info ms-auto" onClick={handleAdd}>
                <i className="bx bx-plus"></i>ADD
              </a>
            )}
            {isAdd && (
              <a
                className="btn btn-outline-secondary ms-auto"
                onClick={handleAdd}
              >
                <i className="bx bx-left-arrow-alt"></i> Back
              </a>
            )}
          </div>
          {!isAdd && (
            <div className="card">
              {!isLoading && ticketList?.length > 0 && (
                <div className="card-body">
                  <div className="table-responsive mt-3">
                    <table className="table align-middle table-s-c">
                      <thead className="table-light">
                        <tr>
                          <th>Name</th>
                          <th>Price</th>
                          <th>Ticket</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ticketList?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div className="">
                                <div className="">
                                  <p className="mb-0">{item?.name}</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              {item?.price === 0
                                ? 'Free'
                                : `$${item?.price?.toFixed(2)}`}
                            </td>
                            <td>
                              <p>
                                <ion-icon name="ticket-outline"></ion-icon>
                              </p>
                            </td>

                            <td>
                              <p>{item?.statusName}</p>
                            </td>
                            <td>
                              <div className="table-actions d-flex align-items-center justify-content-center gap-3 fs-6">
                                <div
                                  className="widget-icon rounded-circle bg-light-purple text-purple"
                                  onClick={() => handleEdit(item)}
                                >
                                  <ion-icon
                                    name="create-outline"
                                    role="img"
                                    class="md hydrated"
                                  ></ion-icon>
                                </div>
                                <div className="widget-icon rounded-circle bg-light-purple text-purple d-none">
                                  <ion-icon
                                    name="copy-outline"
                                    role="img"
                                    class="md hydrated"
                                  ></ion-icon>
                                </div>
                                <div
                                  className="widget-icon rounded-circle bg-light-purple text-purple"
                                  onClick={(e) => handleDelete(e, item.id)}
                                >
                                  <ion-icon
                                    name="trash-outline"
                                    role="img"
                                    class="md hydrated"
                                  ></ion-icon>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          )}
          {!isAdd && (
            <>{!isLoading && ticketList?.length === 0 && <NotFoundPage />}</>
          )}
          {isAdd && (
            <AddTicketType
              event={event}
              handleAdd={handleAdd}
              fetchTicket={fetchTicket}
              selectedTicekt={selectedTicekt}
            />
          )}
        </div>
      </div>
    </>
  );
};

TicketType.propTypes = {
  event: PropTypes.object,
};

export default TicketType;
