import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from '../../../../../../../Utils/axiosInstance';
import {
  EVENT_TICKET_ENDPOINT,
  PROMOCODE_ENDPOINT,
} from '../../../../../../../Utils/Endpoints';
import Logger from '../../../../../../../Utils/Logger';
import moment from 'moment';
import Multiselect from 'multiselect-react-dropdown';

const AddPromoCode = ({
  event,
  handleAdd,
  fetchPromoCode,
  selectedPromoCode,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [generalError, setGeneralError] = useState('');

  const [promoCode, setPromoCode] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountType, setDiscountType] = useState('');
  const [availableTo, setAvailableTo] = useState('');
  const [availableFrom, setAvailableFrom] = useState('');
  const [availability, setAvailability] = useState('');
  const [selectedTicketIds, setSelectedTicketIds] = useState([]);

  const [ticketList, setTicketList] = useState([]);

  useEffect(() => {
    fetchTicket();
  }, []);

  const fetchTicket = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        EVENT_TICKET_ENDPOINT + `${event.id}/tickets`
      );
      if (response.data.code === 200) {
        setTicketList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedPromoCode?.id && ticketList) {
      setPromoCode(selectedPromoCode.code);
      setDiscountAmount(selectedPromoCode.discount_amount);
      setAvailability(selectedPromoCode.availability);
      setAvailableFrom(
        moment
          .unix(selectedPromoCode?.available_from)
          .format('YYYY-MM-DDTHH:mm')
      );
      setAvailableTo(
        moment.unix(selectedPromoCode?.available_to).format('YYYY-MM-DDTHH:mm')
      );
      setDiscountType(selectedPromoCode.discount_type);

      // Set selectedTicketIds based on matching ticket_id with ticketList
      const matchedTickets = selectedPromoCode.tickets
        .map((ticket) =>
          ticketList.find((ticketItem) => ticketItem.id === ticket.ticket_id)
        )
        .filter((ticket) => ticket !== undefined); // Filter out any undefined values

      setSelectedTicketIds(matchedTickets);
    }
  }, [selectedPromoCode, ticketList]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError('');
    setIsLoading(true);
    try {
      const filteredArray = selectedTicketIds.map((item) => item.id);
      const requestObject = {
        code: promoCode?.toUpperCase(),
        discount_amount: discountAmount,
        discount_type: discountType,
        availability: availability,
        available_from: moment(availableFrom).unix(),
        available_to: moment(availableTo).unix(),
        tickets: filteredArray,
      };

      let response;
      if (selectedPromoCode?.id) {
        response = await axiosInstance.patch(
          `${PROMOCODE_ENDPOINT}${event?.id}/${selectedPromoCode.id}`,
          requestObject
        );
      } else {
        response = await axiosInstance.post(
          PROMOCODE_ENDPOINT + `${event?.id}`,
          requestObject
        );
      }
      if (response.data.code !== 200) {
        setGeneralError(response.data.message);
        return;
      }
      await handleAdd();
      await handleClear();
      await fetchPromoCode();
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    setPromoCode('');
    setDiscountAmount('');
    setAvailability('');
    setAvailableFrom('');
    setAvailableTo('');
    setDiscountType('');
  };

  const onSelect = (option) => {
    setSelectedTicketIds(option);
  };

  const onRemove = (option) => {
    setSelectedTicketIds(option);
  };

  return (
    <div className="card">
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="bs-stepper-content">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="code" className="form-label mt-3">
                  Enter Promo Code
                </label>
                <input
                  type="text"
                  id="code"
                  className="form-control"
                  value={promoCode}
                  required
                  onChange={(e) => setPromoCode(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="discount" className="form-label mt-3">
                  Discount Amount
                </label>
                <input
                  type="number"
                  id="discount"
                  className="form-control"
                  value={discountAmount}
                  required
                  onChange={(e) => setDiscountAmount(e.target.value)}
                />
              </div>
            </div>

            <div className="mt-3">
              <div className="row">
                <label htmlFor="discountType" className="pb-2">
                  Discount Type
                </label>
                <div className="col-sm-12 col-md-6 col-xl-6">
                  <div className="align-items-center bg-secondary-dark border-1 card d-flex  form-check form-check-info gap-3 hover-border-info justify-content-center p-2 text-center user-select-none">
                    <input
                      className="form-check-input scale"
                      name="discountType"
                      type="radio"
                      id="fix"
                      required
                      value="FIX"
                      checked={discountType === 'FIX'}
                      onChange={(e) => setDiscountType(e.target.value)}
                    />
                    <label htmlFor="Fix" className="pe-4">
                      Fix
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-xl-6">
                  <div className="align-items-center bg-secondary-dark border-1 card d-flex  form-check form-check-info gap-3 hover-border-info justify-content-center p-2 text-center user-select-none">
                    <input
                      className="form-check-input scale"
                      name="discountType"
                      type="radio"
                      id="Percentage"
                      value="PERCENTAGE"
                      checked={discountType === 'PERCENTAGE'}
                      onChange={(e) => setDiscountType(e.target.value)}
                    />

                    <label htmlFor="Percentage">PERCENTAGE</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="availableFrom"
                    className="form-label mt-3 mt-md-0"
                  >
                    From
                  </label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    id="availableFrom"
                    value={availableFrom}
                    required
                    min={new Date().toISOString().slice(0, 16)}
                    onChange={(e) => setAvailableFrom(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="availableTo"
                    className="form-label mt-3  mt-md-0"
                  >
                    To
                  </label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    id="availableTo"
                    value={availableTo}
                    required
                    min={new Date().toISOString().slice(0, 16)}
                    onChange={(e) => setAvailableTo(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="availability" className="form-label mt-3">
                    Availability
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={availability}
                    id="availability"
                    required
                    onChange={(e) => setAvailability(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tickets" className="form-label mt-3">
                    Tickets
                  </label>
                  <Multiselect
                    required
                    isObject={true}
                    disabled={ticketList?.length === 0}
                    options={ticketList}
                    selectedValues={selectedTicketIds}
                    onSelect={onSelect}
                    onRemove={onRemove}
                    displayValue="name"
                    id="tickets"
                  />
                </div>
              </div>
            </div>

            {/* <select
              name="Status"
              id="tickets"
              className="form-select"
              value={ticket}
              disabled={ticketList?.length === 0}
              required
              onChange={(e) => setTicket(e.target.value)}
            >
              <option value="">Select Tickets</option>
              {ticketList?.map((item, index) => (
                <option value={item?.id} key={index}>
                  {item?.name}
                </option>
              ))}
            </select> */}
          </div>
          <div className="col-12 mt-4">
            <div className="d-flex align-items-center gap-3">
              <button
                className="btn btn-outline-warning px-4"
                type="submit"
                disabled={isLoading}
              >
                {isLoading
                  ? 'Loading...'
                  : selectedPromoCode?.id
                    ? 'Update'
                    : 'Save'}
              </button>
            </div>
            {generalError && (
              <div className="error text-center">{generalError}</div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

AddPromoCode.propTypes = {
  event: PropTypes.object,
  handleAdd: PropTypes.func,
  fetchPromoCode: PropTypes.func,
  selectedPromoCode: PropTypes.object,
};

export default AddPromoCode;
