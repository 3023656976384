import React, { useEffect, useState } from 'react';
import OrgLayout from './Components/OrgLayout';
import { Link } from 'react-router-dom';
import Chart from 'react-apexcharts';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { ORGANIZATION_EVENT_ENDPOINT } from '../../../../../Utils/Endpoints';
import Logger from '../../../../../Utils/Logger';
import moment from 'moment';
import NotFoundPage from '../../Components/NotFoundPage';
import axiosInstance from '../../../../../Utils/axiosInstance';
import { getObject } from '../../../../../Utils/LocalStorage';
import BarCode from './Components/BarCode';
const data = [
  { time: '04:30', series1: 40, series2: 30 },
  { time: '05:00', series1: 70, series2: 35 },
  { time: '05:30', series1: 100, series2: 41 },
  { time: '06:00', series1: 90, series2: 40 },
  { time: '06:15', series1: 85, series2: 38 },
];

const OrgDashboard = () => {
  const [upcomingEventList, setUpcomingEventList] = useState([]);
  const [totalItemsUpcoming, setTotalItemsUpcoming] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const selectedOrg = getObject('selectedOrganization');

  const state = {
    options: {
      chart: {
        type: 'area',
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      },
      title: {
        text: 'Monthly Sales Data',
        align: 'left',
      },
      fill: {
        opacity: 1,
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0.5,
          gradientToColors: ['#ff7c43'], // optional
          inverseColors: false,
          stops: [0, 100],
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
    },
    series: [
      {
        name: 'Sales',
        data: [30, 40, 35, 50, 49, 60, 70],
      },
    ],
  };

  useEffect(() => {
    fetchUpcomingEvent();
  }, []);

  const fetchUpcomingEvent = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        ORGANIZATION_EVENT_ENDPOINT +
          `${selectedOrg.id}/events?event_start_from=${moment().unix()}&per_page=4`
      );
      if (response.data.code === 200) {
        setUpcomingEventList(response.data.data.data);
        setTotalItemsUpcoming(response.data.data.total);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <OrgLayout>
        <div className="page-breadcrumb d-flex align-items-center mb-3">
          <div className="font-22">Home</div>
        </div>
        <div className="row row-cols-1 row-cols-lg-4">
          <div className="col">
            <div className="card radius-10">
              <div className="card-body">
                <div className="d-flex align-items-start gap-2">
                  <div>
                    <p className="mb-0 fs-6">Total Revenue</p>
                  </div>
                  <div className="ms-auto widget-icon-small text-white bg-gradient-purple">
                    <ion-icon name="wallet-outline"></ion-icon>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <div>
                    <h4 className="mb-0">$92,854</h4>
                  </div>
                  <div className="ms-auto">+6.32%</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card radius-10">
              <div className="card-body">
                <div className="d-flex align-items-start gap-2">
                  <div>
                    <p className="mb-0 fs-6">Total Events</p>
                  </div>
                  <div className="ms-auto widget-icon-small text-white bg-gradient-success">
                    <ion-icon name="bar-chart-outline"></ion-icon>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <div>
                    <h4 className="mb-0">45</h4>
                  </div>
                  <div className="ms-auto">+14</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card radius-10">
              <div className="card-body">
                <div className="d-flex align-items-start gap-2">
                  <div>
                    <p className="mb-0 fs-6">Tickets Sold</p>
                  </div>
                  <div className="ms-auto widget-icon-small text-white bg-gradient-danger">
                    <ion-icon name="bag-handle-outline"></ion-icon>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <div>
                    <h4 className="mb-0">8,234</h4>
                  </div>
                  <div className="ms-auto">+3.12%</div>
                </div>
              </div>
            </div>
          </div>

          <BarCode barCodeData={selectedOrg} type="ORG" />
        </div>
        <div className="row">
          <div className="col-12 col-lg-8 d-flex">
            <div className="card radius-10 w-100">
              <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">Earning Reports</h6>
                  <div className="dropdown options ms-auto">
                    <div
                      className="dropdown-toggle dropdown-toggle-nocaret"
                      data-bs-toggle="dropdown"
                    >
                      <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                    </div>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row g-4 align-items-center mb-4">
                  <div className="col-12 col-xl-4">
                    <div className="d-flex align-items-center gap-2 mb-3">
                      <h1 className="mb-0">$856</h1>
                      <p className="mb-0 text-success bg-light-success px-2 rounded py-1">
                        +10.6%
                      </p>
                    </div>
                    <p className="mb-0">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate.
                    </p>
                  </div>
                  <div className="col-12 col-xl-8">
                    {/* <div id="chart3"></div> */}
                    <ResponsiveContainer style={{ width: '100%' }} height={270}>
                      <AreaChart data={data}>
                        <defs>
                          <linearGradient
                            id="colorSeries1"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="#0288d1"
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor="#0288d1"
                              stopOpacity={0}
                            />
                          </linearGradient>
                          <linearGradient
                            id="colorSeries2"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="#00c853"
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor="#00c853"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <XAxis dataKey="time" />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Area
                          type="monotone"
                          dataKey="series1"
                          stroke="#0288d1"
                          fillOpacity={1}
                          fill="url(#colorSeries1)"
                        />
                        <Area
                          type="monotone"
                          dataKey="series2"
                          stroke="#00c853"
                          fillOpacity={1}
                          fill="url(#colorSeries2)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div className="d-flex flex-column flex-lg-row align-items-lg-center align-self-end justify-content-lg-between border p-3 gap-3 mb-0 rounded-3">
                  <div className="d-flex align-items-center gap-3">
                    <div className="widget-icon rounded-circle bg-light-success text-success">
                      <ion-icon name="card-outline"></ion-icon>
                    </div>
                    <div className="">
                      <h4 className="mb-0">$95,286.50</h4>
                      <p className="mb-0 text-secondary">Total Revenue</p>
                    </div>
                  </div>
                  <div className="vr d-none d-lg-block"></div>
                  <div className="d-flex align-items-center gap-3">
                    <div className="widget-icon rounded-circle bg-light-info text-info">
                      <ion-icon name="diamond-outline"></ion-icon>
                    </div>
                    <div className="">
                      <h4 className="mb-0">$58,820</h4>
                      <p className="mb-0 text-secondary">Total Profit</p>
                    </div>
                  </div>
                  <div className="vr d-none d-lg-block"></div>
                  <div className="d-flex align-items-center gap-3">
                    <div className="widget-icon rounded-circle bg-light-purple text-purple">
                      <ion-icon name="people-circle-outline"></ion-icon>
                    </div>
                    <div className="">
                      <h4 className="mb-0">$26,498</h4>
                      <p className="mb-0 text-secondary">Total Customer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="card radius-10 overflow-hidden w-100">
              <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">Total Traffic</h6>
                  <div className="dropdown options ms-auto">
                    <div
                      className="dropdown-toggle dropdown-toggle-nocaret"
                      data-bs-toggle="dropdown"
                    >
                      <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                    </div>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="d-flex align-items-center font-13 gap-2">
                  <span className="border px-1 rounded cursor-pointer">
                    <i className="bx bxs-circle me-1 text-tiffany"></i>Views
                  </span>
                  <span className="border px-1 rounded cursor-pointer">
                    <i className="bx bxs-circle me-1 text-success"></i>Shares
                  </span>
                </div>
                {/* <div id="chart2"></div> */}
                <Chart
                  options={state.options}
                  series={state.series}
                  type="area"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card p-3">
          <h6>Upcoming Events</h6>
          <hr />
          {!isLoading && upcomingEventList?.length === 0 && <NotFoundPage />}
          {!isLoading && upcomingEventList?.length > 0 && (
            <div className="row row-cols-2 row-cols-md-3 row-cols-xl-4 g-3">
              {upcomingEventList?.map((event, index) => (
                <div className="col" key={index}>
                  <div className="card bg-secondary-dark mb-0 border-1 hover-border-info h-100">
                    <img
                      src={
                        event?.banner?.fullUrl
                          ? event?.banner?.fullUrl
                          : '../images/thumbnail.png'
                      }
                      className="card-img-top empty"
                      alt="..."
                    />
                    <div className="card-body border-bottom h-100">
                      <h5 className="card-title">{event?.name}</h5>
                      <p className="card-text">
                        {event?.location?.addressString
                          ? event?.location?.addressString
                          : '136 2nd Ave, New York, NY 10003, USA'}
                      </p>
                    </div>

                    <div className="card-body border-top">
                      <Link
                        to="/edit-events-user"
                        state={{ event: event }}
                        className="btn btn-sm btn-outline-warning me-2"
                      >
                        Edit Detail
                      </Link>
                      <span className="float-md-end badge bg-info text-dark d-none">
                        Live
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {totalItemsUpcoming > 4 && (
            <div className="col-md-12 text-align-right mt-2">
              <Link to="/events" className="btn btn-sm btn-outline-info me-2">
                View All
              </Link>
            </div>
          )}
        </div>
      </OrgLayout>
    </>
  );
};

export default OrgDashboard;
