import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const MapComponent = ({ lat, lng, city }) => {
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCoordinates = async () => {
      if (city) {
        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(city)}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
          );
          const data = await response.json();

          if (data.status === 'OK') {
            const location = data.results[0].geometry.location;
            setCoordinates({ lat: location.lat, lng: location.lng });
          } else {
            setError('Unable to get coordinates for the provided location.');
          }
        } catch (err) {
          setError('Error fetching coordinates.');
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchCoordinates();
  }, [city]);

  const { lat: fetchedLat, lng: fetchedLng } = coordinates;

  let mapUrl;

  if (lat && lng) {
    // If latitude and longitude are provided
    mapUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=${lat},${lng}`;
    // mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=14&size=800x350&markers=color:red%7C${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
  } else if (fetchedLat && fetchedLng) {
    // If coordinates have been fetched from the city
    mapUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=${fetchedLat},${fetchedLng}`;
    // mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${fetchedLat},${fetchedLng}&zoom=14&size=800x350&markers=color:red%7C${fetchedLat},${fetchedLng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
  }

  return (
    <>
      {loading && <p>Loading map...</p>}
      {error && <p>{error}</p>}
      {mapUrl ? (
        <iframe
          src={mapUrl}
          style={{ border: '0', width: '100%', height: '100%' }} // Set width to 100% and specify a height
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      ) : (
        <p>No location data available.</p>
      )}
    </>
  );
};

MapComponent.propTypes = {
  lat: PropTypes.any,
  lng: PropTypes.any,
  city: PropTypes.any,
};
export default MapComponent;
