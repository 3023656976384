import React from 'react';

const NotFoundPage = () => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body text-center py-5">
              <img src="../images/cross-not-found.svg" alt="not-found-image" />
              <div className="font-22 pt-3">Record Not Found</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
