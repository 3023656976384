import { useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../../Utils/axiosInstance';
import {
  SIGNUP_STEP_ONE_ENDPOINT,
  SIGNUP_STEP_TWO_ENDPOINT,
} from '../../../../Utils/Endpoints';
import { saveObject, setLoginSeller } from '../../../../Utils/LocalStorage';
import { Link } from 'react-router-dom';
// Validation schema
const SignUpStepOneSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required.'),
});

const Signup = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState('');
  const [succMsg, setSuccMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');

  const navigate = useNavigate();

  const handleNavigation = (url) => {
    navigate(url);
  };

  const validate = () => {
    try {
      setGeneralError('');
      SignUpStepOneSchema.validateSync({ email }, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmitOne = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(SIGNUP_STEP_ONE_ENDPOINT, {
        email,
      });
      if (response.data.code === 200) {
        setToken(response?.data?.data?.confirmation_token);
        setSuccMsg(response.data.message);
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitTwo = async (e) => {
    e.preventDefault();
    setGeneralError('');
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(SIGNUP_STEP_TWO_ENDPOINT, {
        email,
        confirmation_token: token,
        code: otp,
      });
      if (response.data.code === 200) {
        const { token, user } = response.data.data;
        saveObject('sellerToken', token);
        setLoginSeller(user);
        setSuccMsg(response.data.message);
        setTimeout(() => {
          setSuccMsg('');
          handleNavigation('/profile-update');
        }, 1000);
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="wrapper">
        <div className="">
          <div className="row g-0 m-0">
            <div className="col-xl-6 col-lg-12">
              <div className="login-cover-wrapper">
                <div className="logo">
                  <img
                    src="../images/logo-icon-2.png"
                    className="logo-icon"
                    alt="logo icon"
                  />
                  <h4 className="logo-text text-warning">PARTYPASS</h4>
                </div>
                <div className="card shadow-none">
                  <div className="card-body">
                    <div className="text-center">
                      <h4>Sign Up</h4>
                      <p>Create new account</p>
                    </div>
                    {token === '' && (
                      <form
                        className="form-body row g-3"
                        onSubmit={handleSubmitOne}
                      >
                        <div className="col-12">
                          <label htmlFor="inputEmail" className="form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="inputEmail"
                            value={email}
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="off"
                          />
                          {errors?.email && (
                            <div className="error">{errors.email}</div>
                          )}
                          <div className="spacer" />

                          {generalError && (
                            <div className="error">{generalError}</div>
                          )}
                        </div>
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                            disabled={isLoading}
                          >
                            Continue
                          </button>
                        </div>
                        <div className="col-12 text-center">
                          <p
                            className="mb-0"
                            onClick={() => handleNavigation('/login')}
                          >
                            Already have an account?
                            <Link to="/login"> Login</Link>
                          </p>
                        </div>
                      </form>
                    )}
                    {token && (
                      <form
                        className="form-body row g-3"
                        onSubmit={handleSubmitTwo}
                      >
                        {succMsg && (
                          <div className="col-12 text-success">{succMsg}</div>
                        )}
                        <div className="col-12">
                          <label htmlFor="inputEmail" className="form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="inputEmail"
                            value={email}
                            placeholder="Email"
                            disabled
                            autoComplete="off"
                          />
                        </div>
                        <div className="col-12">
                          <label htmlFor="inputOtp" className="form-label">
                            Enter OTP
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputOtp"
                            maxLength={4}
                            name="otp"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            placeholder="Please Enter OTP"
                            autoComplete="off"
                            required
                          />
                          {errors?.otp && (
                            <div className="error">{errors.otp}</div>
                          )}
                        </div>
                        <div className="col-12">
                          <div className="d-grid">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isLoading}
                            >
                              Create Account
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="position-fixed top-0 h-100 d-xl-block d-none login-cover-img"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
