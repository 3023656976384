import React, { useEffect, useState } from 'react';
import Layout from './Components/Layout';
import { Link } from 'react-router-dom';
import axiosInstance from '../../../Utils/axiosInstance';
import Logger from '../../../Utils/Logger';
import { ORGANIZATION_ENDPOINT } from '../../../Utils/Endpoints';
import { saveObject } from '../../../Utils/LocalStorage';
import NotFoundPage from './Components/NotFoundPage';
import Pagination from './Components/Pagination';

const OrganiziationListing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [organiziation, setOrganiziation] = useState([]);

  // Pagination
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchOrganiziation();
  }, [currentPage]);

  const fetchOrganiziation = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        ORGANIZATION_ENDPOINT + `?page=${currentPage}&per_page=${itemsPerPage}`
      );
      if (response.data.code === 200) {
        setTotalItems(response.data.data.total);
        setOrganiziation(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOrganisation = (item) => {
    saveObject('selectedOrganization', item);
  };

  return (
    <>
      <Layout>
        <div className="page-breadcrumb d-flex align-items-center mb-3">
          <div className="font-22">My Organizations</div>
          <Link to="/create-org" className="btn btn-outline-info ms-auto">
            <i className="bx bx-plus"></i>Create Organization
          </Link>
        </div>
        {!isLoading && organiziation?.length > 0 && (
          <div className="card p-3">
            <div className="row row-cols-2 row-cols-md-3 row-cols-xl-4 g-3">
              {organiziation?.map((item, index) => (
                <div className="col" key={index}>
                  <div className="card bg-secondary-dark mb-0 border-1 hover-border-info h-100">
                    <img
                      src={
                        item?.banner?.fullUrl
                          ? item?.banner?.fullUrl
                          : '../images/thumbnail.png'
                      }
                      className="card-img-top empty"
                      alt="..."
                    />
                    <div className="card-body border-bottom h-100">
                      <h5 className="card-title">{item?.name}</h5>
                      <p className="card-text">
                        {item?.location?.addressString
                          ? item?.location?.addressString
                          : '136 2nd Ave, New York, NY 10003, USA'}
                      </p>
                    </div>

                    <div className="card-body border-top">
                      <Link
                        to="/org-dashboard"
                        onClick={() => handleOrganisation(item)}
                        // state={{ organisationData: item }}
                        className="btn btn-sm btn-outline-warning me-2"
                      >
                        View Dashboard
                      </Link>
                      <span className="float-md-end badge bg-info text-dark d-none">
                        Live
                      </span>
                    </div>
                  </div>
                </div>
              ))}

              {/* <div className="col d-none">
              <div className="card bg-secondary-dark mb-0 border-1 hover-border-info h-100">
                <img
                  src="https://media.chillzapp.com/uploads/pictures/5766945044b1d8deff93cb06193adff8.png"
                  className="card-img-top empty"
                  alt="..."
                />
                <div className="card-body border-bottom h-100">
                  <h5 className="card-title">NYC Nightlife</h5>
                  <p className="card-text">
                    136 2nd Ave, New York, NY 10003, USA
                  </p>
                </div>

                <div className="card-body border-top">
                  <Link
                    to="/org-dashboard"
                    className="btn btn-sm btn-outline-warning me-2"
                  >
                    View Dashboard
                  </Link>
                  <span className="float-md-end badge bg-info text-dark">
                    Live
                  </span>
                </div>
              </div>
            </div>

            <div className="col d-none">
              <div className="card bg-secondary-dark mb-0 border-1 hover-border-info h-100">
                <img
                  src="https://media.chillzapp.com/uploads/pictures/69e92894ba5591280148e0af56b58da7.jpeg"
                  className="card-img-top empty"
                  alt="..."
                />
                <div className="card-body border-bottom h-100">
                  <h5 className="card-title">Nightmare</h5>
                  <p className="card-text">
                    410 8th Ave, New York, NY 10001, USA
                  </p>
                </div>

                <div className="card-body border-top">
                  <Link
                    to="/org-dashboard"
                    className="btn btn-sm btn-outline-warning me-2"
                  >
                    View Dashboard
                  </Link>
                  <span className="float-md-end badge bg-primary">Draft</span>
                </div>
              </div>
            </div>

            <div className="col d-none">
              <div className="card bg-secondary-dark mb-0 border-1 hover-border-info h-100">
                <img
                  src="https://media.chillzapp.com/uploads/pictures/a60aed8b2d08aa96c8ed77dbbcc2d6c2.jpeg"
                  className="card-img-top empty"
                  alt="..."
                />
                <div className="card-body border-bottom h-100">
                  <h5 className="card-title">AFTER PROM</h5>
                  <p className="card-text">New York, NY</p>
                </div>

                <div className="card-body border-top">
                  <Link
                    to="/org-dashboard"
                    className="btn btn-sm btn-outline-warning me-2"
                  >
                    View Dashboard
                  </Link>
                  <span className="float-md-end badge bg-primary">Draft</span>
                </div>
              </div>
            </div> */}
            </div>
            {totalItems > 10 && (
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        )}

        {!isLoading && organiziation?.length === 0 && (
          <div className="card p-3">
            <NotFoundPage />
          </div>
        )}
      </Layout>
    </>
  );
};

export default OrganiziationListing;
