import React, { useEffect, useState } from 'react';
import AddGuest from './AddGuest';
import Logger from '../../../../../../../Utils/Logger';
import NotFoundPage from '../../../../Components/NotFoundPage';

const Guests = () => {
  const [isAdd, setIsAdd] = useState(false);
  const [guestList, setGuestList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [selectedGuest, setSelectedGuest] = useState({});

  const handleAdd = () => {
    setIsAdd(!isAdd);
    // setSelectedGuest({});
  };

  useEffect(() => {
    fetchGuest();
  }, []);

  const fetchGuest = async () => {
    try {
      setIsLoading(true);
      // const response = await axiosInstance.get(
      //   EVENT_TICKET_ENDPOINT + `${event.id}/tickets`
      // );
      // if (response.data.code === 200) {
      // setTicketList(response.data.data.data);
      // }
      setGuestList([]);
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="page-breadcrumb d-flex align-items-center mb-3">
        <div className="font-22">Guests</div>
        {!isAdd && (
          <a
            className="btn btn-outline-info ms-auto"
            onClick={(e) => e.preventDefault()}
          >
            <i className="bx bx-plus"></i>ADD
          </a>
        )}
        {isAdd && (
          <a className="btn btn-outline-secondary ms-auto" onClick={handleAdd}>
            <i className="bx bx-left-arrow-alt"></i> Back
          </a>
        )}
      </div>
      {!isAdd && (
        <div className="card">
          {!isLoading && guestList?.length > 0 && (
            <div className="card-body">
              <div className="table-responsive mt-3">
                <table className="table align-middle table-s-c">
                  <thead className="table-light">
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>ID Number</th>
                      <th>Phone</th>
                      <th>Facebook</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p>Ajay Chayal</p>
                      </td>
                      <td>
                        <a href="mailto:">ajay.chayal@yatilabs.com</a>
                      </td>
                      <td>
                        <p>1234566AK</p>
                      </td>
                      <td>
                        <p>(+91) 9888888888</p>
                      </td>
                      <td>
                        <a href="#">ajay kumar</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
      {!isAdd && (
        <>{!isLoading && guestList?.length === 0 && <NotFoundPage />}</>
      )}
      {isAdd && <AddGuest />}
    </>
  );
};

export default Guests;
