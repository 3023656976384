import React, { useState } from 'react';
import OrgLayout from '../Components/OrgLayout';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Summary from './Components/Summary';
import TicketType from './Components/TicketType';
import PromoCodes from './Components/PromoCodes';
import Guests from './Components/Guests';
import Edit from './Components/Edit';
import Setting from './Components/Setting';
import moment from 'moment';
import EventDeleteModal from './Components/EventDeleteModal';

const EditEventsUser = () => {
  const location = useLocation();
  const eventObject = location.state && location.state.event;
  const [selectedData, setSelectedData] = useState({});
  const [activeTab, setActiveTab] = useState('warning-pills-home');
  const navigate = useNavigate();

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const handleDelete = () => {
    navigate('/events');
  };

  return (
    <>
      <OrgLayout>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h4>{eventObject?.name}</h4>
            <p>
              {moment
                .unix(eventObject?.start_date_time_unix)
                .format('MMMM D, YYYY hh:mm A ')}
              • {eventObject?.location?.city?.name}
            </p>
          </div>
          <div className="d-flex gap-2 ">
            <div
              className="widget-icon rounded-circle bg-light-info text-info"
              data-bs-toggle="modal"
              data-bs-target="#exampleModalEvent"
              onClick={() =>
                setSelectedData({
                  id: eventObject?.id,
                  parentId: eventObject?.organization_id,
                  type: 'event',
                })
              }
            >
              <ion-icon
                name="trash-outline"
                role="img"
                className="md hydrated"
              ></ion-icon>
            </div>
            <Link to="" className="d-none">
              <div className="widget-icon rounded-circle bg-light-info text-info">
                <ion-icon
                  name="link"
                  role="img"
                  className="md hydrated"
                ></ion-icon>
              </div>
            </Link>
            <Link to="" className="d-none">
              <div className="widget-icon rounded-circle bg-light-info text-info">
                <ion-icon
                  name="copy-outline"
                  role="img"
                  className="md hydrated"
                ></ion-icon>
              </div>
            </Link>
            <Link to="" className="d-none">
              <div className="widget-icon rounded-circle bg-light-info text-info">
                <ion-icon
                  name="eye-outline"
                  role="img"
                  className="md hydrated"
                ></ion-icon>
              </div>
            </Link>
          </div>
        </div>
        <div className="mt-4">
          <ul className="mb-3 nav nav-tabs nav-warning" role="tablist">
            <li className="nav-item " role="presentation">
              <a
                className="nav-link active"
                data-bs-toggle="pill"
                href="#warning-pills-home"
                role="tab"
                aria-selected="true"
              >
                <div className="d-flex align-items-center gap-2 justify-content-center">
                  <div className="tab-icon">
                    <ion-icon
                      name="newspaper-outline"
                      className="me-1 md hydrated"
                      role="img"
                      aria-label="home sharp"
                    ></ion-icon>
                  </div>
                  <div className="tab-title">Summary</div>
                </div>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                data-bs-toggle="pill"
                href="#warning-pills-profile"
                role="tab"
                aria-selected="false"
                tabIndex="-1"
              >
                <div className="d-flex align-items-center gap-2 justify-content-center">
                  <div className="tab-icon">
                    <ion-icon
                      name="ticket-outline"
                      className="me-1 md hydrated"
                      role="img"
                      aria-label="person sharp"
                    ></ion-icon>
                  </div>
                  <div className="tab-title">Ticket Types</div>
                </div>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                data-bs-toggle="pill"
                href="#warning-pills-contact"
                role="tab"
                aria-selected="false"
                tabIndex="-1"
              >
                <div className="d-flex align-items-center gap-2 justify-content-center">
                  <div className="tab-icon">
                    <ion-icon
                      name="color-palette-outline"
                      className="me-1 md hydrated"
                      role="img"
                      aria-label="call sharp"
                    ></ion-icon>
                  </div>
                  <div className="tab-title">Promo Codes</div>
                </div>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                data-bs-toggle="pill"
                href="#warning-pills-guests"
                role="tab"
                aria-selected="false"
                tabIndex="-1"
              >
                <div className="d-flex align-items-center gap-2 justify-content-center">
                  <div className="tab-icon">
                    <ion-icon
                      name="people-circle-outline"
                      className="me-1 md hydrated"
                      role="img"
                      aria-label="call sharp"
                    ></ion-icon>
                  </div>
                  <div className="tab-title">Guests</div>
                </div>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                data-bs-toggle="pill"
                href="#warning-pills-edit"
                role="tab"
                aria-selected="false"
                tabIndex="-1"
              >
                <div className="d-flex align-items-center gap-2 justify-content-center">
                  <div className="tab-icon">
                    <ion-icon
                      name="pencil-outline"
                      className="me-1 md hydrated"
                      role="img"
                      aria-label="call sharp"
                    ></ion-icon>
                  </div>
                  <div className="tab-title">Edit</div>
                </div>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                data-bs-toggle="pill"
                href="#warning-pills-setting"
                role="tab"
                aria-selected="false"
                tabIndex="-1"
              >
                <div className="d-flex align-items-center gap-2 justify-content-center">
                  <div className="tab-icon">
                    <ion-icon
                      name="settings-outline"
                      className="me-1 md hydrated"
                      role="img"
                      aria-label="call sharp"
                    ></ion-icon>
                  </div>
                  <div className="tab-title">Setting</div>
                </div>
              </a>
            </li>
            {/* <a
              className="align-items-center btn btn-outline-info d-flex ms-auto"
              href="#"
            >
              <i className="bx bx-plus"></i> Save
            </a>
            <a
              className="btn btn-outline-secondary ms-2 align-items-center d-flex"
              href="#"
            >
              <i className="bx bx-left-arrow-alt"></i> Back
            </a> */}
          </ul>
          <div className="">
            <div className="">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="warning-pills-home"
                  role="tabpanel"
                >
                  <Summary event={eventObject} />
                </div>
                <div
                  className="tab-pane fade"
                  id="warning-pills-profile"
                  role="tabpanel"
                >
                  <TicketType event={eventObject} />
                </div>
                <div
                  className="tab-pane fade"
                  id="warning-pills-contact"
                  role="tabpanel"
                >
                  <PromoCodes event={eventObject} />
                </div>
                <div
                  className="tab-pane fade"
                  id="warning-pills-guests"
                  role="tabpanel"
                >
                  <Guests event={eventObject} />
                </div>
                <div
                  className="tab-pane fade"
                  id="warning-pills-edit"
                  role="tabpanel"
                >
                  <Edit event={eventObject} />
                </div>
                <div
                  className="tab-pane fade"
                  id="warning-pills-setting"
                  role="tabpanel"
                >
                  <Setting event={eventObject} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 d-none">
          <ul className="mb-3 nav nav-tabs nav-warning" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${activeTab === 'warning-pills-home' ? 'active' : ''}`}
                onClick={() => handleTabChange('warning-pills-home')}
                role="tab"
              >
                <div className="d-flex align-items-center gap-2 justify-content-center">
                  <div className="tab-icon">
                    <ion-icon
                      name="newspaper-outline"
                      className="me-1 md hydrated"
                      role="img"
                      aria-label="home sharp"
                    ></ion-icon>
                  </div>
                  <div className="tab-title">Summary</div>
                </div>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${activeTab === 'warning-pills-profile' ? 'active' : ''}`}
                onClick={() => handleTabChange('warning-pills-profile')}
                role="tab"
              >
                <div className="d-flex align-items-center gap-2 justify-content-center">
                  <div className="tab-icon">
                    <ion-icon
                      name="ticket-outline"
                      className="me-1 md hydrated"
                      role="img"
                      aria-label="person sharp"
                    ></ion-icon>
                  </div>
                  <div className="tab-title">Ticket Types</div>
                </div>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${activeTab === 'warning-pills-contact' ? 'active' : ''}`}
                onClick={() => handleTabChange('warning-pills-contact')}
                role="tab"
              >
                <div className="d-flex align-items-center gap-2 justify-content-center">
                  <div className="tab-icon">
                    <ion-icon
                      name="color-palette-outline"
                      className="me-1 md hydrated"
                      role="img"
                      aria-label="call sharp"
                    ></ion-icon>
                  </div>
                  <div className="tab-title">Promo Codes</div>
                </div>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${activeTab === 'warning-pills-guests' ? 'active' : ''}`}
                onClick={() => handleTabChange('warning-pills-guests')}
                role="tab"
              >
                <div className="d-flex align-items-center gap-2 justify-content-center">
                  <div className="tab-icon">
                    <ion-icon
                      name="people-circle-outline"
                      className="me-1 md hydrated"
                      role="img"
                      aria-label="call sharp"
                    ></ion-icon>
                  </div>
                  <div className="tab-title">Guests</div>
                </div>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${activeTab === 'warning-pills-edit' ? 'active' : ''}`}
                onClick={() => handleTabChange('warning-pills-edit')}
                role="tab"
              >
                <div className="d-flex align-items-center gap-2 justify-content-center">
                  <div className="tab-icon">
                    <ion-icon
                      name="pencil-outline"
                      className="me-1 md hydrated"
                      role="img"
                      aria-label="call sharp"
                    ></ion-icon>
                  </div>
                  <div className="tab-title">Edit</div>
                </div>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${activeTab === 'warning-pills-setting' ? 'active' : ''}`}
                onClick={() => handleTabChange('warning-pills-setting')}
                role="tab"
              >
                <div className="d-flex align-items-center gap-2 justify-content-center">
                  <div className="tab-icon">
                    <ion-icon
                      name="settings-outline"
                      className="me-1 md hydrated"
                      role="img"
                      aria-label="call sharp"
                    ></ion-icon>
                  </div>
                  <div className="tab-title">Setting</div>
                </div>
              </a>
            </li>
          </ul>
          <div className="tab-content">
            {activeTab === 'warning-pills-home' && <Summary />}
            {activeTab === 'warning-pills-profile' && (
              <TicketType event={eventObject} />
            )}
            {activeTab === 'warning-pills-contact' && (
              <PromoCodes event={eventObject} />
            )}
            {activeTab === 'warning-pills-guests' && <Guests />}
            {activeTab === 'warning-pills-edit' && <Edit event={eventObject} />}
            {activeTab === 'warning-pills-setting' && <Setting />}
          </div>
        </div>
        <EventDeleteModal
          selectedData={selectedData}
          handleUpdateList={handleDelete}
        />
      </OrgLayout>
    </>
  );
};

export default EditEventsUser;
