import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axiosInstance from '../../../../Utils/axiosInstance';
import { LOGIN_ENDPOINT } from '../../../../Utils/Endpoints';
import { saveObject, setLoginSeller } from '../../../../Utils/LocalStorage';

// Validation schema
const LogInSchema = Yup.object().shape({
  email: Yup.string().email().required('Please enter your email.'),
  password: Yup.string().min(8).required('Please enter your password.'),
});

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (url) => {
    navigate(url);
  };

  const validate = () => {
    try {
      setGeneralError('');
      LogInSchema.validateSync({ email, password }, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(LOGIN_ENDPOINT, {
        email,
        password,
      });
      if (response.data.code === 200) {
        const { token, user } = response.data.data;
        saveObject('sellerToken', token);
        setLoginSeller(user);
        handleNavigation('/dashboard');
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="wrapper">
        <div className="">
          <div className="row g-0 m-0">
            <div className="col-xl-6 col-lg-12">
              <div className="login-cover-wrapper">
                <div className="logo">
                  <img
                    src="../images/logo-icon-2.png"
                    className="logo-icon"
                    alt="logo icon"
                  />
                  <h4 className="logo-text text-warning">PARTYPASS</h4>
                </div>
                <div className="card shadow-none">
                  <div className="card-body">
                    <div className="text-center">
                      <h4>Sign In</h4>
                      <p>Sign In to your account</p>
                    </div>
                    <form
                      className="form-body row g-3 login-form"
                      onSubmit={handleSubmit}
                    >
                      <div className="col-12">
                        <label htmlFor="inputEmail" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="inputEmail"
                          name="email"
                          value={email}
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                          autoComplete="off"
                        />
                        {errors.email && (
                          <div className="error">{errors.email}</div>
                        )}
                      </div>
                      <div className="col-12">
                        <label htmlFor="inputPassword" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="inputPassword"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                          autoComplete="off"
                        />
                        {errors.password && (
                          <div className="error">{errors.password}</div>
                        )}
                        {generalError && (
                          <div className="error">{generalError}</div>
                        )}
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckRemember"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckRemember"
                          >
                            Remember Me
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 text-end">
                        <Link to="/forgot">Forgot Password?</Link>
                      </div>
                      <div className="col-12 col-lg-12">
                        <div className="d-grid">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isLoading}
                          >
                            Sign In
                          </button>
                        </div>
                      </div>

                      <div className="col-12 col-lg-12 text-center">
                        <p className="mb-0">
                          Don&apos;t have an account?
                          <Link to="/signup"> Sign up</Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="position-fixed top-0 h-100 d-xl-block d-none login-cover-img"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
