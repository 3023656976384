import React from 'react';
import { Link } from 'react-router-dom';
// import EventCard from './EventCard';

const HomeCardPreview = () => {
  return (
    <>
      <div className="edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <header className="edgtf-page-header">
              <div className="edgtf-menu-area edgtf-menu-left">
                <div className="edgtf-vertical-align-containers">
                  <div className="edgtf-position-left">
                    <div className="edgtf-position-left-inner">
                      <div className="edgtf-logo-wrapper">
                        <Link to="#">
                          <img
                            width="40"
                            height="40"
                            alt="logo"
                            src="../images/emblem.png"
                          />
                          PartyPass
                        </Link>
                      </div>
                      <nav className="edgtf-main-menu edgtf-drop-down edgtf-default-nav">
                        <ul id="menu-main-menu-navigation" className="clearfix">
                          <li className="menu-item edgtf-active-item">
                            <Link to="#" className="current ">
                              <span className="item_outer">
                                <span className="item_text">Home</span>
                              </span>
                            </Link>
                          </li>
                          <li className="menu-item">
                            <Link to="#" className="current ">
                              <span className="item_outer">
                                <span className="item_text">Events</span>
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="edgtf-position-right">
                    <div className="edgtf-position-right-inner">
                      <button
                        type="button"
                        // onClick={() => handleNavigation('/signup')}
                        style={{ color: '#ffffff', borderColor: '#ffffff' }}
                        className="edgtf-btn edgtf-btn-custom-border-hover edgtf-btn-custom-hover-bg edgtf-btn-custom-hover-color edgtf-btn-outline"
                        data-hover-color="#000000"
                        data-hover-bg-color="#ffffff"
                        data-hover-border-color="#ffffff"
                      >
                        <span className="edgtf-btn-text">Sign Up</span>
                      </button>
                      <Link
                        to="/login"
                        style={{ marginLeft: '20px' }}
                        className="edgtf-btn edgtf-btn-medium edgtf-btn-solid edgtf-btn-orange-white"
                      >
                        <span className="edgtf-btn-text">Login</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <form className="edgtf-search-cover" method="get">
                <div className="edgtf-form-holder-outer">
                  <div className="edgtf-form-holder">
                    <div className="edgtf-form-holder-inner">
                      <input
                        type="text"
                        placeholder="Search here..."
                        name="s"
                        className="edgtf_search_field"
                        autoComplete="off"
                      />
                      <Link
                        className="edgtf-search-close edgtf-search-close-icon-pack"
                        to="#"
                      >
                        <span
                          aria-hidden="true"
                          className="edgtf-icon-font-elegant icon_close "
                        ></span>{' '}
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </header>
            {/* new html start */}
            <div className="edgtf-content">
              <div className="edgtf-content-inner">
                <div
                  className="edgtf-title-holder edgtf-standard-type edgtf-title-va-header-bottom"
                  style={{ height: '150px', backgroundColor: '#000000' }}
                  data-height="150"
                >
                  <div
                    className="edgtf-title-wrapper"
                    style={{ height: '150px' }}
                  >
                    <div className="edgtf-title-inner">
                      <div className="edgtf-grid">
                        <p
                          className="edgtf-page-subtitle"
                          style={{ color: '#f45f0b' }}
                        >
                          Event Name
                        </p>
                        <h2 className="edgtf-page-title entry-title">
                          Taj Lounge NYC Saturday Night&lsquo;s Gametight
                          Guestlist 2024
                          <span
                            className="edgtf-page-title-suffix"
                            style={{ color: '#f45f0b' }}
                          >
                            .
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="offbeat-event-single-holder">
                  <div className="edgtf-full-width">
                    <div className="edgtf-full-width-inner">
                      <div className="edgtf-grid">
                        <div className="offbeat-event-top-holder edgtf-grid-row edgtf-grid-huge-gutter preview-tt">
                          <div className="offbeat-event-top-right edgtf-grid-col-4 off-beat px-md-2 px-3">
                            <div className="offbeat-event-featured-image">
                              <img
                                width="800"
                                height="943"
                                src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/event-img-1.jpg"
                                className="attachment-full size-full wp-post-image"
                                alt="efqw"
                                decoding="async"
                                // fetchpriority="high"
                                srcSet="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/event-img-1.jpg 800w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/event-img-1-98x116.jpg 98w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/event-img-1-255x300.jpg 255w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/event-img-1-768x905.jpg 768w"
                                sizes="(max-width: 800px) 100vw, 800px"
                              />{' '}
                            </div>
                            <div className="user-ticket-details">
                              <h4 className="offbeat-event-info-title">
                                Details
                              </h4>
                              <div className="offbeat-event-info-item offbeat-event-date">
                                <span className="offbeat-event-info-item-title">
                                  Date:
                                </span>
                                <span className="offbeat-event-info-item-desc">
                                  September 11, 2018
                                </span>
                              </div>
                              <div className="offbeat-event-info-item offbeat-event-date">
                                <span className="offbeat-event-info-item-title">
                                  Duration:
                                </span>
                                <span className="offbeat-event-info-item-desc">
                                  1 Day
                                </span>
                              </div>
                              <div className="offbeat-event-info-item offbeat-event-categories">
                                <span className="offbeat-event-info-item-title">
                                  Category:
                                </span>
                                <span className="offbeat-event-info-item-desc">
                                  <a
                                    itemProp="url"
                                    className="edgtf-item-info-category"
                                    href="https://offbeat.qodeinteractive.com/event-category/lounge/"
                                  >
                                    Lounge
                                  </a>
                                </span>
                              </div>
                              <div className="offbeat-event-info-item offbeat-event-location">
                                <span className="offbeat-event-info-item-title">
                                  Type:
                                </span>
                                <span className="offbeat-event-info-item-desc">
                                  open
                                </span>
                              </div>
                              <div className="offbeat-event-info-item offbeat-event-location">
                                <span className="offbeat-event-info-item-title">
                                  Entrants:
                                </span>
                                <span className="offbeat-event-info-item-desc">
                                  100
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="offbeat-event-top-left edgtf-grid-col-8 px-md-2 px-3">
                            <div className="offbeat-event-content-wrap clearfix">
                              <div className="offbeat-event-text-main content-center">
                                <div className="offbeat-event-content">
                                  <div className="wpb-content-wrapper">
                                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1519891401815">
                                      <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                          <div className="wpb_wrapper">
                                            <div className="edgtf-google-map-holder">
                                              <div className="edgtf-google-map">
                                                <iframe
                                                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52943468.081439875!2d-161.94000782076728!3d35.912393491540506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1730198124844!5m2!1sen!2sin"
                                                  //   width="600"
                                                  height="450"
                                                  style={{ border: '0' }}
                                                  allowfullscreen=""
                                                  loading="lazy"
                                                  referrerPolicy="no-referrer-when-downgrade"
                                                ></iframe>
                                              </div>
                                              <div className="edgtf-google-map-overlay"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="vc_row wpb_row vc_row-fluid">
                                      <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                          <div className="wpb_wrapper">
                                            <div
                                              className="vc_empty_space"
                                              style={{ height: '53px' }}
                                            >
                                              <span className="vc_empty_space_inner"></span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="vc_row wpb_row vc_row-fluid"
                                      style={{ paddingTop: '140px' }}
                                    >
                                      <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                          <div className="wpb_wrapper">
                                            <div className="wpb_text_column wpb_content_element">
                                              <div className="wpb_wrapper">
                                                <h4>About Tour</h4>
                                              </div>
                                            </div>
                                            <div
                                              className="vc_empty_space"
                                              style={{ height: '6px' }}
                                            >
                                              <span className="vc_empty_space_inner"></span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="vc_row wpb_row vc_row-fluid">
                                      <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                          <div className="wpb_wrapper">
                                            <div className="wpb_text_column wpb_content_element">
                                              <div className="wpb_wrapper">
                                                <p>
                                                  Lorem ipsum dolor sit amet,
                                                  est ad graecis principes. Ad
                                                  vis iisque saperet. Eu eos
                                                  quod affert. Vim invidunt
                                                  efficiendi ea, eu eos veniam
                                                  percipit dignissim, an cum
                                                  suas laudem. Eum eu ipsum
                                                  mentitum delectus. Te vix
                                                  solet consulatu expetendis.
                                                  Dictas elige ndi antiopam has
                                                  ne, admodum hendrerit eu vis,
                                                  sit nonumy oporere eu. Ei qui
                                                  solet offendit. Ius no graeco
                                                  possim aeterno, eam at omnium
                                                  diceret accumsan. Eu nec
                                                  iisque utroque, ad qui veniam
                                                  hendrerit. Quas pertinax
                                                  vulputate cu mea, no duo
                                                  ubique.
                                                </p>
                                                <p>
                                                  FREE Admission for up to 4
                                                  people
                                                </p>
                                                <p>
                                                  express entry (no waiting in
                                                  line)
                                                </p>
                                                <p>
                                                  all gratuity and tax charges
                                                </p>
                                                <p>
                                                  a “march” to your table with
                                                  our waitresses holding your
                                                  bottles and sparklers
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>{' '}
                              </div>
                              <div className="offbeat-event-social content-center">
                                <div className="edgtf-social-share-holder edgtf-list">
                                  <h4 className="edgtf-social-title">
                                    Follow and Share
                                  </h4>
                                  <ul>
                                    <li className="edgtf-facebook-share">
                                      <a
                                        itemProp="url"
                                        className="edgtf-share-link"
                                        href="#"
                                        onClick="window.open('https://www.facebook.com/sharer.php?u=https%3A%2F%2Foffbeat.qodeinteractive.com%2Fevent%2Fgreenspan%2F', 'sharer', 'toolbar=0,status=0,width=620,height=280');"
                                      >
                                        <span className="edgtf-social-network-icon social_facebook_circle"></span>
                                      </a>
                                    </li>
                                    <li className="edgtf-twitter-share">
                                      <a
                                        itemProp="url"
                                        className="edgtf-share-link"
                                        href="#"
                                        onClick="window.open('https://twitter.com/intent/tweet?text=https://offbeat.qodeinteractive.com/event/greenspan/', 'popupwindow', 'scrollbars=yes,width=800,height=400');"
                                      >
                                        <span className="edgtf-social-network-icon social_twitter_circle"></span>
                                      </a>
                                    </li>
                                    <li className="edgtf-linkedin-share">
                                      <a
                                        itemProp="url"
                                        className="edgtf-share-link"
                                        href="#"
                                        onClick="popUp=window.open('https://www.linkedin.com/sharing/share-offsite?url==https%3A%2F%2Foffbeat.qodeinteractive.com%2Fevent%2Fgreenspan%2F&amp;title=Greenspan', 'popupwindow', 'scrollbars=yes,width=800,height=400');popUp.focus();return false;"
                                      >
                                        <span className="edgtf-social-network-icon social_linkedin_circle"></span>
                                      </a>
                                    </li>
                                    <li className="edgtf-tumblr-share">
                                      <a
                                        itemProp="url"
                                        className="edgtf-share-link"
                                        href="#"
                                        onClick="popUp=window.open('https://www.tumblr.com/share/link?url=https%3A%2F%2Foffbeat.qodeinteractive.com%2Fevent%2Fgreenspan%2F&amp;name=Greenspan&amp;description=', 'popupwindow', 'scrollbars=yes,width=800,height=400');popUp.focus();return false;"
                                      >
                                        <span className="edgtf-social-network-icon social_tumblr_circle"></span>
                                      </a>
                                    </li>{' '}
                                  </ul>
                                </div>{' '}
                              </div>
                              {/* ticket new create */}
                              <div className="wpb_wrapper pt-5">
                                <div className="offbeat-event-list-holder offbeat-event-list-standard edgtf-light-skin edgtf-el-no-columns edgtf-normal-space">
                                  <div className="offbeat-event-list-holder-inner">
                                    <div className="edgtf-el-item content-center">
                                      <a
                                        className="edgtf-el-item-link-outer"
                                        href="#"
                                      ></a>
                                      <div className="edgtf-el-item-date-holder">
                                        <div className="edgtf-el-item-date">
                                          <span className="edgtf-el-item-day pe-3">
                                            $3.00
                                          </span>
                                          <div className="edgtf-el-item-mw d-none">
                                            <span className="edgtf-el-item-month">
                                              Jun
                                            </span>
                                            <span className="edgtf-el-item-weekday">
                                              Tue
                                            </span>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="edgtf-el-item-title-holder">
                                        <h4 className="edgtf-el-item-title">
                                          Collective{' '}
                                        </h4>
                                        <div className="ps-4 d-flex gap-2">
                                          <small>Start Date:27-10-24</small>
                                          <small>End Date:27-10-24</small>
                                        </div>
                                      </div>

                                      <div className="edgtf-el-item-link-holder">
                                        <span className="edgtf-sold-out text-danger">
                                          Sold Out
                                        </span>
                                      </div>
                                    </div>
                                    <div className="edgtf-el-item content-center">
                                      <a
                                        className="edgtf-el-item-link-outer"
                                        href="#"
                                      ></a>
                                      <div className="edgtf-el-item-date-holder">
                                        <div className="edgtf-el-item-date">
                                          <span className="edgtf-el-item-day pe-3">
                                            $4.00
                                          </span>
                                          <div className="edgtf-el-item-mw d-none">
                                            <span className="edgtf-el-item-month">
                                              Jun
                                            </span>
                                            <span className="edgtf-el-item-weekday">
                                              Thu
                                            </span>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="edgtf-el-item-title-holder">
                                        <h4 className="edgtf-el-item-title">
                                          Nadaville
                                        </h4>
                                        <div className="ps-4 d-flex gap-2">
                                          <small>Start Date:27-10-24</small>
                                          <small>End Date:27-10-24</small>
                                        </div>
                                      </div>

                                      <div className="edgtf-el-item-link-holder buy-now">
                                        <select
                                          className="form-select"
                                          aria-label="Default select example"
                                        >
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                        </select>
                                        <a
                                          itemProp="url"
                                          href=""
                                          target="_self"
                                          className="edgtf-btn  edgtf-btn-large edgtf-btn-solid edgtf-btn-orange-white edgtf-el-item-link"
                                        >
                                          <span className="edgtf-btn-text">
                                            Buy Now
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* ticket new create */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="edgtf-content-bottom d-none"
                  style={{ backgroundColor: '#000000' }}
                >
                  <div className="edgtf-content-bottom-inner edgtf-full-width">
                    <div className="widget edgtf-separator-widget">
                      <div className="edgtf-separator-holder clearfix  edgtf-separator-center edgtf-separator-full-width">
                        <div
                          className="edgtf-separator"
                          style={{
                            borderStyle: 'solid',
                            width: '100%',
                            borderBottomWidth: '1px',
                            marginTop: '0px',
                            marginBottom: '130px',
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="widget widget_media_image">
                      <a href="https://offbeat.qodeinteractive.com/">
                        <img
                          width="424"
                          height="87"
                          src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/04/logo-content-bottom.png"
                          className="image wp-image-4366  attachment-full size-full"
                          alt="f"
                          style={{ maxWidth: '100%', height: 'auto' }}
                          decoding="async"
                          loading="lazy"
                          srcSet="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/04/logo-content-bottom.png 424w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/04/logo-content-bottom-150x31.png 150w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/04/logo-content-bottom-300x62.png 300w"
                          sizes="(max-width: 424px) 100vw, 424px"
                        />
                      </a>
                    </div>
                    <div className="widget edgtf-separator-widget">
                      <div className="edgtf-separator-holder clearfix  edgtf-separator-center edgtf-separator-normal">
                        <div
                          className="edgtf-separator"
                          style={{ borderStyle: 'solid', marginBottom: '0px' }}
                        ></div>
                      </div>
                    </div>
                    <div className="widget widget_nav_menu">
                      <div className="menu-footer-menu-container">
                        <ul id="menu-footer-menu" className="menu">
                          <li
                            id="menu-item-1002"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-1002"
                          >
                            <a href="https://offbeat.qodeinteractive.com/">
                              Home
                            </a>
                          </li>
                          <li
                            id="menu-item-3428"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3428"
                          >
                            <a href="https://offbeat.qodeinteractive.com/reservation-form/">
                              Reservation Form
                            </a>
                          </li>
                          <li
                            id="menu-item-1005"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1005"
                          >
                            <a href="https://offbeat.qodeinteractive.com/about-me/">
                              About
                            </a>
                          </li>
                          <li
                            id="menu-item-1006"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1006"
                          >
                            <a href="https://offbeat.qodeinteractive.com/menu/">
                              Menu
                            </a>
                          </li>
                          <li
                            id="menu-item-3430"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3430"
                          >
                            <a href="https://offbeat.qodeinteractive.com/blog/standard-right-sidebar/">
                              Blog
                            </a>
                          </li>
                          <li
                            id="menu-item-3429"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3429"
                          >
                            <a href="https://offbeat.qodeinteractive.com/contact-us/">
                              Contact
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="widget edgtf-separator-widget">
                      <div className="edgtf-separator-holder clearfix  edgtf-separator-center edgtf-separator-normal">
                        <div
                          className="edgtf-separator"
                          style={{ borderStyle: 'solid', marginBottom: '2px' }}
                        ></div>
                      </div>
                    </div>
                    <div className="widget edgtf-social-icons-group-widget text-align-center">
                      <Link
                        className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                        data-hover-color="#f45f0b"
                        style={{ color: '#ffffff' }}
                        to="https://www.instagram.com/qodeinteractive/"
                        target="_blank"
                      >
                        <span className="edgtf-social-icon-widget social_instagram_circle"></span>{' '}
                      </Link>
                      <Link
                        className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                        data-hover-color="#f45f0b"
                        style={{ color: '#ffffff' }}
                        to="https://www.facebook.com/QodeInteractive/"
                        target="_blank"
                      >
                        <span className="edgtf-social-icon-widget social_facebook_circle"></span>{' '}
                      </Link>
                      <Link
                        className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                        data-hover-color="#f45f0b"
                        style={{ color: '#ffffff' }}
                        to="https://plus.google.com/"
                        target="_blank"
                      >
                        <span className="edgtf-social-icon-widget social_googleplus_circle"></span>{' '}
                      </Link>
                      <Link
                        className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                        data-hover-color="#f45f0b"
                        style={{ color: '#ffffff' }}
                        to="https://twitter.com/QodeInteractive"
                        target="_blank"
                      >
                        <span className="edgtf-social-icon-widget social_twitter_circle"></span>{' '}
                      </Link>
                      <Link
                        className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                        data-hover-color="#f45f0b"
                        style={{ color: '#ffffff' }}
                        to="https://www.youtube.com/QodeInteractiveVideos"
                        target="_blank"
                      >
                        <span className="edgtf-social-icon-widget social_youtube_circle"></span>{' '}
                      </Link>
                      <Link
                        className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                        data-hover-color="#f45f0b"
                        style={{ color: '#ffffff' }}
                        to="https://vimeo.com/"
                        target="_blank"
                      >
                        <span className="edgtf-social-icon-widget social_vimeo_circle"></span>{' '}
                      </Link>
                    </div>
                    <div className="widget edgtf-separator-widget">
                      <div className="edgtf-separator-holder clearfix  edgtf-separator-center edgtf-separator-normal">
                        <div
                          className="edgtf-separator"
                          style={{
                            borderStyle: 'solid',
                            marginBottom: '115px',
                          }}
                        ></div>
                      </div>
                    </div>{' '}
                  </div>
                </div>
              </div>
            </div>
            {/* new html start */}
            <footer className="edgtf-page-footer">
              <div className="edgtf-footer-top-holder d-none">
                <div className="edgtf-footer-top-inner edgtf-grid">
                  <div className="edgtf-grid-row edgtf-footer-top-alignment-left">
                    <div className="edgtf-column-content edgtf-grid-col-3">
                      <div
                        id="text-7"
                        className="widget edgtf-footer-column-1 widget_text"
                      >
                        <h5 className="edgtf-widget-title">Opening Hours</h5>
                        <div className="textwidget"></div>
                      </div>
                      <div className="widget edgtf-opening-hours-widget">
                        <div className="edgtf-opening-hours-holder">
                          <div className="edgtf-opening-hours-holder-inner">
                            <div
                              className="edgtf-opening-hours-day-holder"
                              style={{ color: '#ffffff' }}
                            >
                              <div className="edgtf-opening-hours-day">
                                Monday
                              </div>
                            </div>
                            <div className="edgtf-opening-hours-line"></div>
                            <div
                              className="edgtf-opening-hours-time"
                              style={{ color: '#f45f0b' }}
                            >
                              CLOSED
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget edgtf-opening-hours-widget">
                        <div className="edgtf-opening-hours-holder">
                          <div className="edgtf-opening-hours-holder-inner">
                            <div
                              className="edgtf-opening-hours-day-holder"
                              style={{ color: '#ffffff' }}
                            >
                              <div className="edgtf-opening-hours-day">
                                Tuesday
                              </div>
                            </div>
                            <div className="edgtf-opening-hours-line"></div>
                            <div
                              className="edgtf-opening-hours-time"
                              style={{ color: '#ffffff' }}
                            >
                              9:00 - 22:00
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget edgtf-opening-hours-widget">
                        <div className="edgtf-opening-hours-holder">
                          <div className="edgtf-opening-hours-holder-inner">
                            <div
                              className="edgtf-opening-hours-day-holder"
                              style={{ color: '#ffffff' }}
                            >
                              <div className="edgtf-opening-hours-day">
                                Wednesday
                              </div>
                            </div>
                            <div className="edgtf-opening-hours-line"></div>
                            <div
                              className="edgtf-opening-hours-time"
                              style={{ color: '#ffffff' }}
                            >
                              9:00 - 22:00
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget edgtf-opening-hours-widget">
                        <div className="edgtf-opening-hours-holder">
                          <div className="edgtf-opening-hours-holder-inner">
                            <div
                              className="edgtf-opening-hours-day-holder"
                              style={{ color: '#ffffff' }}
                            >
                              <div className="edgtf-opening-hours-day">
                                Thursday
                              </div>
                            </div>
                            <div className="edgtf-opening-hours-line"></div>
                            <div
                              className="edgtf-opening-hours-time"
                              style={{ color: '#ffffff' }}
                            >
                              9:00 - 22:00
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget edgtf-opening-hours-widget">
                        <div className="edgtf-opening-hours-holder">
                          <div className="edgtf-opening-hours-holder-inner">
                            <div
                              className="edgtf-opening-hours-day-holder"
                              style={{ color: '#ffffff' }}
                            >
                              <div className="edgtf-opening-hours-day">
                                Friday*
                              </div>
                            </div>
                            <div className="edgtf-opening-hours-line"></div>
                            <div
                              className="edgtf-opening-hours-time"
                              style={{ color: '#ffffff' }}
                            >
                              9:00 - 01:00
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget edgtf-opening-hours-widget">
                        <div className="edgtf-opening-hours-holder">
                          <div className="edgtf-opening-hours-holder-inner">
                            <div
                              className="edgtf-opening-hours-day-holder"
                              style={{ color: '#ffffff' }}
                            >
                              <div className="edgtf-opening-hours-day">
                                Saturday*
                              </div>
                            </div>
                            <div className="edgtf-opening-hours-line"></div>
                            <div
                              className="edgtf-opening-hours-time"
                              style={{ color: '#ffffff' }}
                            >
                              12:00 - 01:00
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget edgtf-opening-hours-widget">
                        <div className="edgtf-opening-hours-holder">
                          <div className="edgtf-opening-hours-holder-inner">
                            <div
                              className="edgtf-opening-hours-day-holder"
                              style={{ color: '#ffffff' }}
                            >
                              <div className="edgtf-opening-hours-day">
                                Sunday
                              </div>
                            </div>
                            <div className="edgtf-opening-hours-line"></div>
                            <div
                              className="edgtf-opening-hours-time"
                              style={{ color: '#ffffff' }}
                            >
                              12:00 - 22:00
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edgtf-column-content edgtf-grid-col-3">
                      <div className="widget edgtf-blog-list-widget">
                        <h5 className="edgtf-widget-title">Latest Posts</h5>
                        <div
                          className="edgtf-blog-list-holder edgtf-bl-minimal  edgtf-bl-one-column edgtf-normal-space edgtf-bl-pag-no-pagination"
                          data-type="minimal"
                          data-number-of-posts="4"
                          data-number-of-columns="1"
                          data-space-between-items="normal"
                          data-orderby="date"
                          data-order="ASC"
                          data-image-size="thumbnail"
                          data-title-tag="h6"
                          data-excerpt-length="40"
                          data-post-info-section="yes"
                          data-post-info-image="yes"
                          data-post-info-author="yes"
                          data-post-info-date="yes"
                          data-post-info-category="yes"
                          data-post-info-comments="no"
                          data-post-info-like="no"
                          data-post-info-share="no"
                          data-pagination-type="no-pagination"
                          data-max-num-pages="5"
                          data-next-page="2"
                        >
                          <div className="edgtf-bl-wrapper edgtf-outer-space">
                            <ul className="edgtf-blog-list">
                              <li className="edgtf-bl-item edgtf-item-space clearfix">
                                <div className="edgtf-bli-inner">
                                  <div className="edgtf-bli-content">
                                    <h6 className="entry-title edgtf-post-title">
                                      <Link
                                        to="#"
                                        title="Modern Interior Design"
                                      >
                                        Modern Interior Design{' '}
                                      </Link>
                                    </h6>
                                    <div className="edgtf-post-info-date entry-date published updated">
                                      <Link to="#">February 20, 2018 </Link>
                                      {/* <meta
																	content="UserComments: 0"> */}
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="edgtf-bl-item edgtf-item-space clearfix">
                                <div className="edgtf-bli-inner">
                                  <div className="edgtf-bli-content">
                                    <h6 className="entry-title edgtf-post-title">
                                      <Link
                                        to="#"
                                        title="Fun And Interesting People"
                                      >
                                        Fun And Interesting People{' '}
                                      </Link>
                                    </h6>
                                    <div className="edgtf-post-info-date entry-date published updated">
                                      <Link to="#">February 20, 2018 </Link>
                                      {/* <meta
																	content="UserComments: 0"> */}
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="edgtf-bl-item edgtf-item-space clearfix">
                                <div className="edgtf-bli-inner">
                                  <div className="edgtf-bli-content">
                                    <h6 className="entry-title edgtf-post-title">
                                      <Link
                                        to="#"
                                        title="Up And Coming Musicians"
                                      >
                                        Up And Coming Musicians{' '}
                                      </Link>
                                    </h6>
                                    <div className="edgtf-post-info-date entry-date published updated">
                                      <Link to="#">February 20, 2018 </Link>
                                      {/* <meta
																	content="UserComments: 0"> */}
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="edgtf-bl-item edgtf-item-space clearfix">
                                <div className="edgtf-bli-inner">
                                  <div className="edgtf-bli-content">
                                    <h6 className="entry-title edgtf-post-title">
                                      <Link to="#" title="Urban Vibe">
                                        Urban Vibe{' '}
                                      </Link>
                                    </h6>
                                    <div className="edgtf-post-info-date entry-date published updated">
                                      <Link to="#">February 20, 2018 </Link>
                                      {/* <meta
																	content="UserComments: 0"> */}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edgtf-column-content edgtf-grid-col-3">
                      <div
                        id="text-3"
                        className="widget edgtf-footer-column-3 widget_text"
                      >
                        <h5 className="edgtf-widget-title">CONTACT US</h5>
                        <div className="textwidget">
                          <p>
                            <Link to="mailto:contact@partypass.com">
                              contact@partypass.com
                            </Link>
                            <br />
                            <Link to="tel:1-562-867-5309">1-562-867-5309</Link>
                          </p>
                        </div>
                      </div>
                      <div
                        id="text-11"
                        className="widget edgtf-footer-column-3 widget_text"
                      >
                        <div className="textwidget">
                          <p>
                            <Link to="https://www.google.rs/maps/place/Charging+Bull/@40.7055242,-74.0133806,20z/data=!4m5!3m4!1s0x0:0xc7db393ae1eff521!8m2!3d40.7055537!4d-74.0134436">
                              Broadway &amp; Morris St, New York
                            </Link>
                            <br />
                            <Link to="https://www.google.rs/maps/place/Autoklub+Vsetin+Czechia/@28.0903625,-80.5700215,17z/data=!3m1!4b1!4m5!3m4!1s0x88de116ac5f97e1b:0xbcd6048a3d229c61!8m2!3d28.0903625!4d-80.5678328">
                              123 6th St. Melbourne, FL
                            </Link>
                          </p>
                        </div>
                      </div>
                      <div
                        id="text-12"
                        className="widget edgtf-footer-column-3 widget_text"
                      >
                        <div className="textwidget">
                          <p>
                            New York City
                            <br />
                            State of New York
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="edgtf-column-content edgtf-grid-col-3">
                      <div
                        id="custom_html-2"
                        className="widget_text widget edgtf-footer-column-4 widget_custom_html"
                      >
                        <h5 className="edgtf-widget-title">FOLLOW US</h5>
                        <div className="textwidget custom-html-widget">
                          For all the latest news and updates, follow us on
                          Twitter: <Link to="#">@partypass.com</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="edgtf-footer-bottom-holder border-top">
                <div className="edgtf-footer-bottom-inner edgtf-grid">
                  <div className="edgtf-grid-row content-center">
                    <div className="edgtf-grid-col-4">
                      <div
                        id="media_image-2"
                        className="widget edgtf-footer-bottom-column-1 widget_media_image"
                      >
                        <div className="edgtf-logo-wrapper">
                          <Link to="#">
                            <img
                              width="40"
                              height="40"
                              alt="logo"
                              src="../images/emblem.png"
                            />
                            PartyPass
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="edgtf-grid-col-4">
                      <div className="widget edgtf-social-icons-group-widget text-align-center">
                        {' '}
                        <Link
                          className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                          style={{ margin: '6px 8px 0 8px' }}
                          to="https://www.instagram.com/qodeinteractive/"
                          target="_blank"
                        >
                          <span className="edgtf-social-icon-widget social_instagram_circle"></span>{' '}
                        </Link>
                        <Link
                          className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                          style={{ margin: '6px 8px 0 8px' }}
                          to="https://www.facebook.com/QodeInteractive/"
                          target="_blank"
                        >
                          <span className="edgtf-social-icon-widget social_facebook_circle"></span>{' '}
                        </Link>
                        <Link
                          className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                          style={{ margin: '6px 8px 0 8px' }}
                          to="https://plus.google.com/"
                          target="_blank"
                        >
                          <span className="edgtf-social-icon-widget social_googleplus_circle"></span>{' '}
                        </Link>
                        <Link
                          className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                          style={{ margin: '6px 8px 0 8px' }}
                          to="https://twitter.com/QodeInteractive"
                          target="_blank"
                        >
                          <span className="edgtf-social-icon-widget social_twitter_circle"></span>{' '}
                        </Link>
                        <Link
                          className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                          style={{ margin: '6px 8px 0 8px' }}
                          to="https://www.youtube.com/QodeInteractiveVideos"
                          target="_blank"
                        >
                          <span className="edgtf-social-icon-widget social_youtube_circle"></span>{' '}
                        </Link>
                        <Link
                          className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                          style={{ margin: '6px 8px 0 8px' }}
                          to="https://vimeo.com/"
                          target="_blank"
                        >
                          <span className="edgtf-social-icon-widget social_vimeo_circle"></span>{' '}
                        </Link>
                      </div>
                    </div>
                    <div className="edgtf-grid-col-4">
                      <div className="widget edgtf-separator-widget">
                        <div className="edgtf-separator-holder clearfix  edgtf-separator-center edgtf-separator-normal">
                          <div
                            className="edgtf-separator"
                            style={{
                              borderColor: ' rgba(255, 255, 255, 0)',
                              borderStyle: 'solid',
                              marginTop: '-8px',
                            }}
                          ></div>
                        </div>
                      </div>
                      <div
                        id="text-6"
                        className="widget edgtf-footer-bottom-column-3 widget_text"
                      >
                        <div className="textwidget">
                          <p>
                            © 2024{' '}
                            <Link
                              to="https://qodeinteractive.com/"
                              target="_blank"
                              rel="noopener"
                            >
                              PartyPass
                            </Link>
                            , All Rights Reserved
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeCardPreview;
