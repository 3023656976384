/* eslint-disable no-useless-escape */
export const phoneRegExp =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

export const emailRegExp =
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;

/* eslint-enable no-useless-escape */

export const downloadBase64Image = (base64Data, filename) => {
  // Create a link element
  const link = document.createElement('a');

  // Set the href attribute to the Base64 string
  link.href = base64Data;

  // Set the download attribute with the desired filename
  link.download = filename;

  // Append the link to the body (necessary for Firefox)
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};

export const encrypt = (type, id) => {
  const combined = `${type}${id}`;
  // Simple encoding logic (you can replace this with a better algorithm)
  let encoded = btoa(combined);
  // Return the first 6 characters of the encoded string
  return encoded.substring(0, 10);
};
