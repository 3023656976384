import { Link, useNavigate } from 'react-router-dom';
import { getObject } from '../../../Utils/LocalStorage';
import EventCard from './EventCard';
import { useEffect, useState } from 'react';
import moment from 'moment';

const Home = () => {
  const navigate = useNavigate();
  const [eventList, setEventList] = useState([]);

  const handleNavigation = (url) => {
    navigate(url);
  };

  useEffect(() => {
    let eventData = getObject('eventList');
    let eventDataList = eventData ? eventData : [];
    setEventList(eventDataList);
  }, []);

  return (
    <>
      <div className="edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear ">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <header className="edgtf-page-header">
              <div className="edgtf-menu-area edgtf-menu-left">
                <div className="edgtf-vertical-align-containers">
                  <div className="edgtf-position-left">
                    <div className="edgtf-position-left-inner">
                      <div className="edgtf-logo-wrapper">
                        <Link to="#">
                          <img
                            width="40"
                            height="40"
                            alt="logo"
                            src="../images/emblem.png"
                          />
                          PartyPass
                        </Link>
                      </div>
                      <nav className="edgtf-main-menu edgtf-drop-down edgtf-default-nav">
                        <ul id="menu-main-menu-navigation" className="clearfix">
                          <li className="menu-item edgtf-active-item">
                            <Link to="#" className="current ">
                              <span className="item_outer">
                                <span className="item_text">Home</span>
                              </span>
                            </Link>
                          </li>
                          <li className="menu-item">
                            <Link to="#" className="current ">
                              <span className="item_outer">
                                <span className="item_text">Events</span>
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="edgtf-position-right">
                    <div className="edgtf-position-right-inner">
                      <button
                        type="button"
                        onClick={() => handleNavigation('/signup')}
                        style={{ color: '#ffffff', borderColor: '#ffffff' }}
                        className="edgtf-btn edgtf-btn-custom-border-hover edgtf-btn-custom-hover-bg edgtf-btn-custom-hover-color edgtf-btn-outline"
                        data-hover-color="#000000"
                        data-hover-bg-color="#ffffff"
                        data-hover-border-color="#ffffff"
                      >
                        <span className="edgtf-btn-text">Sign Up</span>
                      </button>
                      <Link
                        to="/login"
                        style={{ marginLeft: '20px' }}
                        className="edgtf-btn edgtf-btn-medium edgtf-btn-solid edgtf-btn-orange-white"
                      >
                        <span className="edgtf-btn-text">Login</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <form className="edgtf-search-cover" method="get">
                <div className="edgtf-form-holder-outer">
                  <div className="edgtf-form-holder">
                    <div className="edgtf-form-holder-inner">
                      <input
                        type="text"
                        placeholder="Search here..."
                        name="s"
                        className="edgtf_search_field"
                        autoComplete="off"
                      />
                      <Link
                        className="edgtf-search-close edgtf-search-close-icon-pack"
                        to="#"
                      >
                        <span
                          aria-hidden="true"
                          className="edgtf-icon-font-elegant icon_close "
                        ></span>{' '}
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </header>
            <Link id="edgtf-back-to-top" to="#">
              <span className="edgtf-icon-stack edgtf-front-side">
                <span
                  aria-hidden="true"
                  className="edgtf-icon-font-elegant arrow_triangle-up "
                ></span>{' '}
              </span>
              <span className="edgtf-icon-stack edgtf-back-side">
                <span
                  aria-hidden="true"
                  className="edgtf-icon-font-elegant arrow_triangle-up "
                ></span>{' '}
              </span>
            </Link>
            {/* new */}
            <div className="edgtf-content" style={{ marginTop: '-90px' }}>
              <div className="edgtf-content-inner">
                <div className="edgtf-full-width">
                  <div className="edgtf-full-width-inner">
                    <div className="edgtf-grid-row">
                      <div className="edgtf-page-content-holder edgtf-grid-col-12">
                        <div className="wpb-content-wrapper">
                          <div className="vc_row wpb_row vc_row-fluid">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div
                                    className="hero-section"
                                    style={{
                                      backgroundImage:
                                        'url("../images/hero-background.jpg")',
                                    }}
                                  >
                                    <h3>Start with</h3>
                                    <h1>Partypass</h1>
                                    <h2>Feel the beat of the night</h2>
                                    <Link
                                      to="#"
                                      className="edgtf-btn edgtf-btn-medium edgtf-btn-solid edgtf-btn-orange-white"
                                    >
                                      Expore Events
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="vc_row wpb_row vc_row-fluid">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  {/* Events Listing START */}
                                  <div className="events-listing">
                                    <var className="edgtf-st-tagline">
                                      Upcoming Events{' '}
                                    </var>
                                    <div className="outer outer-new">
                                      {eventList?.map((item, index) => (
                                        <EventCard
                                          imageUser={item?.image}
                                          heading={item?.eventName}
                                          text={moment(
                                            item?.startDateTime
                                          ).format('MMMM D, YYYY h:mm A')}
                                          key={index}
                                        />
                                      ))}

                                      <EventCard
                                        imageUser="../images/event-img-1.jpg"
                                        heading="AEPI Hoco Alumni"
                                        text="October 26, 2024 11:00 AM"
                                      />
                                      <EventCard
                                        imageUser="https://media.chillzapp.com/uploads/pictures/053d8d6d6d7e3c153bd714048a213635.jpeg"
                                        heading="AFTER PROM 2025 @HILLS STATE"
                                        text="May 9, 2025 10:00 PM"
                                      />
                                      <EventCard
                                        imageUser="https://media.chillzapp.com/uploads/pictures/d7fa517332a33869103e747e299fdfe7.png"
                                        heading="Friday @ The DL 10/11"
                                        text="October 11, 2024 10:00 PM"
                                      />
                                      <EventCard
                                        imageUser="https://media.chillzapp.com/uploads/pictures/b360646cbceac0a6d449785af16eb498.png"
                                        heading="The Purge @Blend Willamsburg"
                                        text="October 26, 2024 11:00 AM"
                                      />
                                      <EventCard
                                        imageUser="https://media.chillzapp.com/uploads/pictures/674e355888aa530355d68683d4005f7e.jpg"
                                        heading="AVENUES HAUNTED HALLOWEEN"
                                        text="October 26, 2024 11:00 AM"
                                      />
                                      <EventCard
                                        imageUser="https://media.chillzapp.com/uploads/pictures/3418079973dc14f83e373b7918e2f70c.png"
                                        heading=" Daintree Rooftop Saturday Halloween
                                            Party - Tower Of Terror 10/26"
                                        text="October 26, 2024 11:00 AM"
                                      />
                                      <EventCard
                                        imageUser="https://media.chillzapp.com/uploads/pictures/c5d9f0c96b0c2f9afd7aacf1a3c9d586.png"
                                        heading=" Daintree Rooftop Saturday Halloween
                                            Party - Tower Of Terror 10/26"
                                        text="October 26, 2024 11:00 AM"
                                      />
                                      <EventCard
                                        imageUser="https://media.chillzapp.com/uploads/pictures/a6c93958d1f0d018287a51c4ce9746c8.jpeg"
                                        heading="SATURDAYS @ THE DL"
                                        text="October 26, 2024 11:00 AM"
                                      />
                                      <EventCard
                                        imageUser="https://media.chillzapp.com/uploads/pictures/59b8ad4af04bf2e9b10deba2d2edadcc.png"
                                        heading="A View to Kill Halloween at Bar Hugo
                                            Rooftop at the Hugo Hotel Soho"
                                        text="October 26, 2024 11:00 AM"
                                      />
                                    </div>
                                  </div>
                                  {/* Events Listing END */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="edgtf-row-grid-section-wrapper edgtf-content-aligment-center">
                            <div className="edgtf-row-grid-section">
                              <div className="vc_row wpb_row vc_row-fluid vc_custom_1522835334066 web-new">
                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="edgtf-section-title-holder  ">
                                        <div className="edgtf-st-inner">
                                          <var className="edgtf-st-tagline">
                                            Lead the fun.{' '}
                                          </var>
                                          <h1 className="edgtf-st-title">
                                            Find Your Event Now
                                            <span className="edgtf-st-title-dot">
                                              .
                                            </span>
                                          </h1>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: '84px' }}
                                      >
                                        <span className="vc_empty_space_inner"></span>
                                      </div>
                                      <div className="edgtf-rf-holder edgtf-rf-light">
                                        <form
                                          className="edgtf-rf"
                                          target="_blank"
                                          name="edgtf-rf"
                                        >
                                          <div className="edgtf-rf-row clearfix">
                                            <div className="edgtf-rf-col-holder">
                                              <div className="edgtf-rf-field-holder clearfix">
                                                <select
                                                  name="partySize"
                                                  className="edgtf-ot-people"
                                                >
                                                  <option value="1">
                                                    1 Person
                                                  </option>
                                                  <option value="2">
                                                    2 People
                                                  </option>
                                                  <option value="3">
                                                    3 People
                                                  </option>
                                                  <option value="4">
                                                    4 People
                                                  </option>
                                                  <option value="5">
                                                    5 People
                                                  </option>
                                                  <option value="6">
                                                    6 People
                                                  </option>
                                                  <option value="7">
                                                    7 People
                                                  </option>
                                                  <option value="8">
                                                    8 People
                                                  </option>
                                                  <option value="9">
                                                    9 People
                                                  </option>
                                                  <option value="10">
                                                    10 People
                                                  </option>
                                                </select>
                                                <span className="edgtf-rf-icon">
                                                  <span className="edgtf-rf-group"></span>
                                                </span>
                                              </div>
                                            </div>
                                            <div className="edgtf-rf-col-separator">
                                              <span>for</span>
                                            </div>
                                            <div className="edgtf-rf-col-holder">
                                              <div className="edgtf-rf-field-holder clearfix">
                                                <input
                                                  type="text"
                                                  value="10/07/2024"
                                                  className="edgtf-ot-date"
                                                  name="startDate"
                                                />
                                                <span className="edgtf-rf-icon">
                                                  <span className="edgtf-rf-calendar"></span>
                                                </span>
                                              </div>
                                            </div>
                                            <div className="edgtf-rf-col-separator">
                                              <span>at</span>
                                            </div>
                                            <div className="edgtf-rf-col-holder edgtf-rf-time-col">
                                              <div className="edgtf-rf-field-holder clearfix">
                                                <select
                                                  name="ResTime"
                                                  className="edgtf-ot-time"
                                                >
                                                  <option value="6:30am">
                                                    6:30 am
                                                  </option>
                                                  <option value="7:00am">
                                                    7:00 am
                                                  </option>
                                                  <option value="7:30am">
                                                    7:30 am
                                                  </option>
                                                  <option value="8:00am">
                                                    8:00 am
                                                  </option>
                                                  <option value="8:30am">
                                                    8:30 am
                                                  </option>
                                                  <option value="9:00am">
                                                    9:00 am
                                                  </option>
                                                  <option value="9:30am">
                                                    9:30 am
                                                  </option>
                                                  <option value="10:00pm">
                                                    10:00 am
                                                  </option>
                                                  <option value="10:30am">
                                                    10:30 am
                                                  </option>
                                                  <option value="11:00am">
                                                    11:00 am
                                                  </option>
                                                  <option value="11:30am">
                                                    11:30 am
                                                  </option>
                                                  <option value="12:00pm">
                                                    12:00 pm
                                                  </option>
                                                  <option value="12:30pm">
                                                    12:30 pm
                                                  </option>
                                                  <option value="1:00pm">
                                                    1:00 pm
                                                  </option>
                                                  <option value="1:30pm">
                                                    1:30 pm
                                                  </option>
                                                  <option value="2:00pm">
                                                    2:00 pm
                                                  </option>
                                                  <option value="2:30pm">
                                                    2:30 pm
                                                  </option>
                                                  <option value="3:00pm">
                                                    3:00 pm
                                                  </option>
                                                  <option value="3:30pm">
                                                    3:30 pm
                                                  </option>
                                                  <option value="4:00pm">
                                                    4:00 pm
                                                  </option>
                                                  <option value="4:30pm">
                                                    4:30 pm
                                                  </option>
                                                  <option value="5:00pm">
                                                    5:00 pm
                                                  </option>
                                                  <option value="5:30pm">
                                                    5:30 pm
                                                  </option>
                                                  <option value="6:00pm">
                                                    6:00 pm
                                                  </option>
                                                  <option value="6:30pm">
                                                    6:30 pm
                                                  </option>
                                                  <option
                                                    value="7:00pm"
                                                    selected="selected"
                                                  >
                                                    7:00 pm
                                                  </option>
                                                  <option value="7:30pm">
                                                    7:30 pm
                                                  </option>
                                                  <option value="8:00pm">
                                                    8:00 pm
                                                  </option>
                                                  <option value="8:30pm">
                                                    8:30 pm
                                                  </option>
                                                  <option value="9:00pm">
                                                    9:00 pm
                                                  </option>
                                                  <option value="9:30pm">
                                                    9:30 pm
                                                  </option>
                                                  <option value="10:00pm">
                                                    10:00 pm
                                                  </option>
                                                  <option value="10:30pm">
                                                    10:30 pm
                                                  </option>
                                                  <option value="11:00pm">
                                                    11:00 pm
                                                  </option>
                                                  <option value="11:30pm">
                                                    11:30 pm
                                                  </option>
                                                </select>
                                                <span className="edgtf-rf-icon">
                                                  <span className="edgtf-rf-clock"></span>
                                                </span>
                                              </div>
                                            </div>
                                            <div className="edgtf-rf-col-holder edgtf-rf-btn-holder">
                                              <button
                                                type="submit"
                                                className="edgtf-btn edgtf-btn-medium edgtf-btn-solid edgtf-btn-orange-white"
                                              >
                                                <span className="edgtf-btn-text">
                                                  Search
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="vc_row wpb_row vc_row-fluid vc_row-o-content-middle vc_row-flex edgtf-content-aligment-left">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="edgtf-elements-holder edgtf-two-columns edgtf-responsive-mode-1024">
                                    <div className="edgtf-eh-item edgtf-horizontal-alignment-center">
                                      <div className="edgtf-eh-item-inner">
                                        <div className="edgtf-eh-item-content edgtf-eh-custom-3391">
                                          <div className="edgtf-single-image-holder">
                                            <div className="edgtf-si-inner in-ner">
                                              <img
                                                // width="800"
                                                // height="500"
                                                className="attachment-full size-full"
                                                alt="c"
                                                srcSet="images/h1-img-1.jpg 800w, images/h1-img-1-150x94.jpg 150w, images/h1-img-1-300x188.jpg 300w, images/h1-img-1-768x480.jpg 768w"
                                                sizes="(max-width: 800px) 100vw, 800px"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="edgtf-eh-item    "
                                      data-item-className="edgtf-eh-custom-2601"
                                      data-1280-1600="177px 57px 230px 0"
                                      data-1024-1280="177px 13px 230px 0 "
                                      data-768-1024="0 247px 230px 120px"
                                      data-680-768="0 55px 230px 80px"
                                      data-680="80px 15px 230px 15px"
                                    >
                                      <div className="edgtf-eh-item-inner edgtf-in">
                                        <div className="edgtf-eh-item-content edgtf-eh-custom-2601">
                                          <div className="edgtf-section-title-holder  ">
                                            <div className="edgtf-st-inner">
                                              <var className="edgtf-st-tagline">
                                                Be at right place.{' '}
                                              </var>
                                              <h1 className="edgtf-st-title">
                                                Nightclub NYC
                                                <span className="edgtf-st-title-dot">
                                                  .
                                                </span>
                                              </h1>
                                              <p
                                                className="edgtf-st-text"
                                                style={{
                                                  color: '#ffffff',
                                                  marginTop: '23px',
                                                }}
                                              >
                                                When searching for the right
                                                place to get lost and enjoy new,
                                                alternative music, Offbeat
                                                lounge bar is the perfect
                                                choice. With cozy, yes
                                                progressive interior, and kind
                                                staff, we are the best place to
                                                get lost during work days or on
                                                weekends. Enjoy live music
                                                shows.
                                              </p>
                                            </div>
                                          </div>
                                          <div
                                            className="vc_empty_space"
                                            style={{ height: '61px' }}
                                          >
                                            <span className="vc_empty_space_inner"></span>
                                          </div>
                                          <Link
                                            to=""
                                            target="_self"
                                            style={{
                                              color: '#ffffff',
                                              borderColor: '#ffffff',
                                            }}
                                            className="edgtf-btn edgtf-btn-medium edgtf-btn-outline edgtf-btn-orange-black edgtf-btn-custom-hover-bg edgtf-btn-custom-border-hover edgtf-btn-custom-hover-color"
                                            data-hover-color="#000000"
                                            data-hover-bg-color="#ffffff"
                                            data-hover-border-color="#ffffff"
                                          >
                                            <span className="edgtf-btn-text">
                                              Read More
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            data-parallax-bg-image="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/03/h1-video-img.jpg"
                            className="vc_row wpb_row vc_row-fluid vc_row-o-full-height vc_row-o-columns-middle vc_row-o-content-middle vc_row-flex edgtf-parallax-row-holder"
                          >
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="edgtf-elements-holder  edgtf-eh-full-height edgtf-one-column  edgtf-responsive-mode-768 edgtf-one-column-alignment-center">
                                    <div className="edgtf-eh-item   edgtf-horizontal-alignment-center ">
                                      <div className="edgtf-eh-item-inner">
                                        <div className="edgtf-eh-item-content">
                                          <div className="edgtf-video-button-holder ">
                                            <Link
                                              className="edgtf-video-button-play"
                                              to="#"
                                            >
                                              <span className="edgtf-video-button-play-inner">
                                                <span className="edgtf-video-button-circle">
                                                  <span className="edgtf-video-button-triangle"></span>
                                                </span>
                                              </span>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="edgtf-row-grid-section-wrapper edgtf-content-aligment-left"
                            style={{ backgroundColor: '#000000' }}
                          >
                            <div className="edgtf-row-grid-section">
                              <div className="vc_row wpb_row vc_row-fluid vc_custom_1520843122533">
                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div
                                        className="edgtf-section-title-holder  "
                                        style={{ textAlign: 'center' }}
                                      >
                                        <div className="edgtf-st-inner">
                                          <var className="edgtf-st-tagline">
                                            Our finnest{' '}
                                          </var>
                                          <h2 className="edgtf-st-title">
                                            CRAFT BEER Selection
                                            <span className="edgtf-st-title-dot">
                                              .
                                            </span>
                                          </h2>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: '60px' }}
                                      >
                                        <span className="vc_empty_space_inner"></span>
                                      </div>
                                      <div className="edgtf-elements-holder   edgtf-two-columns  edgtf-responsive-mode-1024 edgtf-ui">
                                        <div
                                          className="edgtf-eh-item    "
                                          data-item-className="edgtf-eh-custom-8340"
                                          data-1280-1600="0 0 0 116px"
                                          data-1024-1280="0 0 0 116px"
                                          data-768-1024="0 0 0 0"
                                          data-680-768="0 0 0 0"
                                          data-680="0 0 0 0"
                                        >
                                          <div className="edgtf-eh-item-inner">
                                            <div
                                              className="edgtf-eh-item-content edgtf-eh-custom-8340"
                                              // style={{ padding: '0 0 0 157px' }}
                                            >
                                              <div className="offbeat-pricing-list clearfix offbeat-pli-light offbeat-pli-normal-space">
                                                <div className="offbeat-pl-wrapper">
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-1.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Summit Craft
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$2.95</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Saga IPA, Oatmeal
                                                            Stout, High Sticke
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-3.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Barley Garden
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$3.65</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>Finest IPA</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-5.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Brooklyn{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$4.15</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Defender IPA,
                                                            Scorcher Ipa,
                                                            Sorachi
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-7.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Hobgoblin{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$3.45</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Hobgoblin Gold,
                                                            Hobgoblin Ipa
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-9.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Bluemoon{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$4.65</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Belgian White, Mango
                                                            Wheat
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-11.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Brewdog{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$4.80</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Punk IPA, 5AM Saint,
                                                            Elvis Juice
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="edgtf-eh-item    "
                                          data-item-className="edgtf-eh-custom-5202"
                                          data-1280-1600="0 93px 0 0px"
                                          data-768-1024="0 0 0 0"
                                          data-680-768="0 0 0 0"
                                          data-680="0 0 0 0"
                                        >
                                          <div className="edgtf-eh-item-inner">
                                            <div
                                              className="edgtf-eh-item-content edgtf-eh-custom-5202"
                                              // style={{
                                              //   padding: '0 144px 0 0px',
                                              // }}
                                            >
                                              <div className="offbeat-pricing-list clearfix offbeat-pli-light offbeat-pli-normal-space">
                                                <div className="offbeat-pl-wrapper">
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-2.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Weisse{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$2.95</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Saga IPA, Oatmeal
                                                            Stout, High Sticke
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-4.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Bomber{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$3.65</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>Finest IPA</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-6.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Hill Brothers
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$4.15</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Defender IPA,
                                                            Scorcher Ipa,
                                                            Sorachi
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-8.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Paulaner{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$3.45</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Hobgoblin Gold,
                                                            Hobgoblin Ipa
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-10.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Culture On Tap
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$4.65</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Belgian White, Mango
                                                            Wheat
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-12.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Knocker{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$4.80</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Punk IPA, 5AM Saint,
                                                            Elvis Juice
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: '16px' }}
                                      >
                                        <span className="vc_empty_space_inner"></span>
                                      </div>
                                      <div className="vc_row wpb_row vc_inner vc_row-fluid edgtf-content-aligment-center">
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                          <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                              <Link
                                                to="#"
                                                target="_self"
                                                style={{
                                                  color: '#ffffff',
                                                  backgroundColor: '#f45f0b',
                                                  textTransform: 'uppercase',
                                                }}
                                                className="edgtf-btn edgtf-btn-medium edgtf-btn-solid edgtf-btn-orange-white"
                                              >
                                                <span className="edgtf-btn-text">
                                                  View Menu
                                                </span>
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="vc_row wpb_row vc_row-fluid vc_custom_1523350595066 vc_row-o-full-height vc_row-o-columns-top vc_row-flex"
                            style={{
                              backgroundImage: 'url("../images/h1-img-2.jpg")',
                            }}
                          >
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="offbeat-event-list-holder offbeat-event-list-simple edgtf-light-skin edgtf-el-no-columns edgtf-normal-space">
                                    <div className="offbeat-event-list-holder-inner">
                                      <div className="edgtf-el-item">
                                        <Link
                                          className="edgtf-el-item-link-outer"
                                          to="#"
                                        ></Link>
                                        <div className="edgtf-el-item-title-holder">
                                          <h3 className="edgtf-el-item-title">
                                            Amsterdam’s Jazz Bands September
                                            Battle – Day 1{' '}
                                          </h3>
                                        </div>
                                        <div className="edgtf-el-item-link-holder">
                                          <Link
                                            to="#"
                                            target="_self"
                                            className="edgtf-btn edgtf-btn-medium edgtf-btn-simple edgtf-btn-orange-black edgtf-btn-icon edgtf-el-item-link"
                                          >
                                            <span className="edgtf-btn-text">
                                              Learn More
                                            </span>{' '}
                                            <span
                                              aria-hidden="true"
                                              className="edgtf-icon-font-elegant arrow_triangle-right "
                                            ></span>
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="edgtf-el-item">
                                        <Link
                                          className="edgtf-el-item-link-outer"
                                          to="#"
                                        ></Link>
                                        <div className="edgtf-el-item-title-holder">
                                          <h3 className="edgtf-el-item-title">
                                            Amsterdam’s Jazz Bands September
                                            Battle – Day 2{' '}
                                          </h3>
                                        </div>
                                        <div className="edgtf-el-item-link-holder">
                                          <Link
                                            to="#"
                                            target="_self"
                                            className="edgtf-btn edgtf-btn-medium edgtf-btn-simple edgtf-btn-orange-black edgtf-btn-icon edgtf-el-item-link"
                                          >
                                            <span className="edgtf-btn-text">
                                              Learn More
                                            </span>{' '}
                                            <span
                                              aria-hidden="true"
                                              className="edgtf-icon-font-elegant arrow_triangle-right "
                                            ></span>
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="edgtf-el-item">
                                        <Link
                                          className="edgtf-el-item-link-outer"
                                          to="#"
                                        ></Link>
                                        <div className="edgtf-el-item-title-holder">
                                          <h3 className="edgtf-el-item-title">
                                            Amsterdam’s Jazz Bands September
                                            Battle – Day 3{' '}
                                          </h3>
                                        </div>
                                        <div className="edgtf-el-item-link-holder">
                                          <Link
                                            to="#"
                                            target="_self"
                                            className="edgtf-btn edgtf-btn-medium edgtf-btn-simple edgtf-btn-orange-black edgtf-btn-icon edgtf-el-item-link"
                                          >
                                            <span className="edgtf-btn-text">
                                              Learn More
                                            </span>{' '}
                                            <span
                                              aria-hidden="true"
                                              className="edgtf-icon-font-elegant arrow_triangle-right "
                                            ></span>
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="edgtf-el-item">
                                        <Link
                                          className="edgtf-el-item-link-outer"
                                          to="#"
                                        ></Link>
                                        <div className="edgtf-el-item-title-holder">
                                          <h3 className="edgtf-el-item-title">
                                            Amsterdam’s Jazz Bands September
                                            Battle – Day 4{' '}
                                          </h3>
                                        </div>
                                        <div className="edgtf-el-item-link-holder">
                                          <Link
                                            to="#"
                                            target="_self"
                                            className="edgtf-btn edgtf-btn-medium edgtf-btn-simple edgtf-btn-orange-black edgtf-btn-icon edgtf-el-item-link"
                                          >
                                            <span className="edgtf-btn-text">
                                              Learn More
                                            </span>{' '}
                                            <span
                                              aria-hidden="true"
                                              className="edgtf-icon-font-elegant arrow_triangle-right "
                                            ></span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="vc_row wpb_row vc_row-fluid vc_custom_1520262019848 vc_row-has-fill vc_row-o-content-middle vc_row-flex edgtf-content-aligment-center">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="edgtf-elements-holder   edgtf-two-columns  edgtf-responsive-mode-1024 ">
                                    <div
                                      className="edgtf-eh-item    "
                                      data-item-className="edgtf-eh-custom-9820"
                                      data-1280-1600="42px 0px 75px 93px "
                                      data-1024-1280="42px 0 75px 65px"
                                      data-768-1024="42px 0px 86px 0px"
                                      data-680-768="42px 0px 86px 0px"
                                      data-680="42px 10px 86px 10px"
                                    >
                                      <div className="edgtf-eh-item-inner">
                                        <div className="edgtf-eh-item-content edgtf-eh-custom-9820 edgtf-eh-2">
                                          <div className="wpb_text_column wpb_content_element">
                                            <div className="wpb_wrapper">
                                              <h3>
                                                Don’t Miss Out Fun. Subscribe.
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="edgtf-eh-item"
                                      data-item-className="edgtf-eh-custom-8405"
                                      data-1280-1600="0 118px 0 57px "
                                      data-1024-1280="0 95px 0 60px "
                                      data-768-1024="0 172px 54px 172px "
                                      data-680-768="0 45px 54px 46px"
                                      data-680="0 10px 54px 10px"
                                    >
                                      <div className="edgtf-eh-item-inner">
                                        <div className="edgtf-eh-item-content edgtf-eh-custom-8405 edgtf-eh-3">
                                          <div
                                            className="wpcf7 no-js"
                                            id="wpcf7-f5-p21-o1"
                                            lang="en-US"
                                            dir="ltr"
                                          >
                                            <div className="screen-reader-response">
                                              <p
                                                role="status"
                                                aria-live="polite"
                                                aria-atomic="true"
                                              ></p>
                                              <ul></ul>
                                            </div>
                                            <form
                                              className="wpcf7-form init cf7_custom_style_1 demo"
                                              aria-label="Contact form"
                                              noValidate="novalidate"
                                              data-status="init"
                                            >
                                              <div className="edgtf-two-columns-form-without-space">
                                                <div className="edgtf-column-left">
                                                  <p>
                                                    <span
                                                      className="wpcf7-form-control-wrap"
                                                      data-name="email"
                                                    >
                                                      <input
                                                        size="40"
                                                        maxLength="80"
                                                        className="wpcf7-form-control wpcf7-email wpcf7-text wpcf7-validates-as-email"
                                                        aria-invalid="false"
                                                        placeholder="enter your email"
                                                        value=""
                                                        type="email"
                                                        name="email"
                                                      />
                                                    </span>
                                                  </p>
                                                </div>
                                                <div className="edgtf-column-right">
                                                  <p>
                                                    <input
                                                      className="wpcf7-form-control wpcf7-submit has-spinner"
                                                      type="submit"
                                                      value="SUBSCRIBE"
                                                    />
                                                  </p>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="vc_row wpb_row vc_row-fluid">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner vc_custom_1519635204890">
                                <div className="wpb_wrapper">
                                  <div className="wpb_widgetised_column wpb_content_element">
                                    <div className="wpb_wrapper">
                                      <div className="widget widget_edgtf_instagram_widget"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* new  */}
            <footer className="edgtf-page-footer">
              <div className="edgtf-footer-top-holder">
                <div className="edgtf-footer-top-inner edgtf-grid">
                  <div className="edgtf-grid-row edgtf-footer-top-alignment-left">
                    <div className="edgtf-column-content edgtf-grid-col-3">
                      <div
                        id="text-7"
                        className="widget edgtf-footer-column-1 widget_text"
                      >
                        <h5 className="edgtf-widget-title">Opening Hours</h5>
                        <div className="textwidget"></div>
                      </div>
                      <div className="widget edgtf-opening-hours-widget">
                        <div className="edgtf-opening-hours-holder">
                          <div className="edgtf-opening-hours-holder-inner">
                            <div
                              className="edgtf-opening-hours-day-holder"
                              style={{ color: '#ffffff' }}
                            >
                              <div className="edgtf-opening-hours-day">
                                Monday
                              </div>
                            </div>
                            <div className="edgtf-opening-hours-line"></div>
                            <div
                              className="edgtf-opening-hours-time"
                              style={{ color: '#f45f0b' }}
                            >
                              CLOSED
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget edgtf-opening-hours-widget">
                        <div className="edgtf-opening-hours-holder">
                          <div className="edgtf-opening-hours-holder-inner">
                            <div
                              className="edgtf-opening-hours-day-holder"
                              style={{ color: '#ffffff' }}
                            >
                              <div className="edgtf-opening-hours-day">
                                Tuesday
                              </div>
                            </div>
                            <div className="edgtf-opening-hours-line"></div>
                            <div
                              className="edgtf-opening-hours-time"
                              style={{ color: '#ffffff' }}
                            >
                              9:00 - 22:00
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget edgtf-opening-hours-widget">
                        <div className="edgtf-opening-hours-holder">
                          <div className="edgtf-opening-hours-holder-inner">
                            <div
                              className="edgtf-opening-hours-day-holder"
                              style={{ color: '#ffffff' }}
                            >
                              <div className="edgtf-opening-hours-day">
                                Wednesday
                              </div>
                            </div>
                            <div className="edgtf-opening-hours-line"></div>
                            <div
                              className="edgtf-opening-hours-time"
                              style={{ color: '#ffffff' }}
                            >
                              9:00 - 22:00
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget edgtf-opening-hours-widget">
                        <div className="edgtf-opening-hours-holder">
                          <div className="edgtf-opening-hours-holder-inner">
                            <div
                              className="edgtf-opening-hours-day-holder"
                              style={{ color: '#ffffff' }}
                            >
                              <div className="edgtf-opening-hours-day">
                                Thursday
                              </div>
                            </div>
                            <div className="edgtf-opening-hours-line"></div>
                            <div
                              className="edgtf-opening-hours-time"
                              style={{ color: '#ffffff' }}
                            >
                              9:00 - 22:00
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget edgtf-opening-hours-widget">
                        <div className="edgtf-opening-hours-holder">
                          <div className="edgtf-opening-hours-holder-inner">
                            <div
                              className="edgtf-opening-hours-day-holder"
                              style={{ color: '#ffffff' }}
                            >
                              <div className="edgtf-opening-hours-day">
                                Friday*
                              </div>
                            </div>
                            <div className="edgtf-opening-hours-line"></div>
                            <div
                              className="edgtf-opening-hours-time"
                              style={{ color: '#ffffff' }}
                            >
                              9:00 - 01:00
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget edgtf-opening-hours-widget">
                        <div className="edgtf-opening-hours-holder">
                          <div className="edgtf-opening-hours-holder-inner">
                            <div
                              className="edgtf-opening-hours-day-holder"
                              style={{ color: '#ffffff' }}
                            >
                              <div className="edgtf-opening-hours-day">
                                Saturday*
                              </div>
                            </div>
                            <div className="edgtf-opening-hours-line"></div>
                            <div
                              className="edgtf-opening-hours-time"
                              style={{ color: '#ffffff' }}
                            >
                              12:00 - 01:00
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget edgtf-opening-hours-widget">
                        <div className="edgtf-opening-hours-holder">
                          <div className="edgtf-opening-hours-holder-inner">
                            <div
                              className="edgtf-opening-hours-day-holder"
                              style={{ color: '#ffffff' }}
                            >
                              <div className="edgtf-opening-hours-day">
                                Sunday
                              </div>
                            </div>
                            <div className="edgtf-opening-hours-line"></div>
                            <div
                              className="edgtf-opening-hours-time"
                              style={{ color: '#ffffff' }}
                            >
                              12:00 - 22:00
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edgtf-column-content edgtf-grid-col-3">
                      <div className="widget edgtf-blog-list-widget">
                        <h5 className="edgtf-widget-title">Latest Posts</h5>
                        <div
                          className="edgtf-blog-list-holder edgtf-bl-minimal  edgtf-bl-one-column edgtf-normal-space edgtf-bl-pag-no-pagination"
                          data-type="minimal"
                          data-number-of-posts="4"
                          data-number-of-columns="1"
                          data-space-between-items="normal"
                          data-orderby="date"
                          data-order="ASC"
                          data-image-size="thumbnail"
                          data-title-tag="h6"
                          data-excerpt-length="40"
                          data-post-info-section="yes"
                          data-post-info-image="yes"
                          data-post-info-author="yes"
                          data-post-info-date="yes"
                          data-post-info-category="yes"
                          data-post-info-comments="no"
                          data-post-info-like="no"
                          data-post-info-share="no"
                          data-pagination-type="no-pagination"
                          data-max-num-pages="5"
                          data-next-page="2"
                        >
                          <div className="edgtf-bl-wrapper edgtf-outer-space">
                            <ul className="edgtf-blog-list">
                              <li className="edgtf-bl-item edgtf-item-space clearfix">
                                <div className="edgtf-bli-inner">
                                  <div className="edgtf-bli-content">
                                    <h6 className="entry-title edgtf-post-title">
                                      <Link
                                        to="#"
                                        title="Modern Interior Design"
                                      >
                                        Modern Interior Design{' '}
                                      </Link>
                                    </h6>
                                    <div className="edgtf-post-info-date entry-date published updated">
                                      <Link to="#">February 20, 2018 </Link>
                                      {/* <meta
																	content="UserComments: 0"> */}
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="edgtf-bl-item edgtf-item-space clearfix">
                                <div className="edgtf-bli-inner">
                                  <div className="edgtf-bli-content">
                                    <h6 className="entry-title edgtf-post-title">
                                      <Link
                                        to="#"
                                        title="Fun And Interesting People"
                                      >
                                        Fun And Interesting People{' '}
                                      </Link>
                                    </h6>
                                    <div className="edgtf-post-info-date entry-date published updated">
                                      <Link to="#">February 20, 2018 </Link>
                                      {/* <meta
																	content="UserComments: 0"> */}
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="edgtf-bl-item edgtf-item-space clearfix">
                                <div className="edgtf-bli-inner">
                                  <div className="edgtf-bli-content">
                                    <h6 className="entry-title edgtf-post-title">
                                      <Link
                                        to="#"
                                        title="Up And Coming Musicians"
                                      >
                                        Up And Coming Musicians{' '}
                                      </Link>
                                    </h6>
                                    <div className="edgtf-post-info-date entry-date published updated">
                                      <Link to="#">February 20, 2018 </Link>
                                      {/* <meta
																	content="UserComments: 0"> */}
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="edgtf-bl-item edgtf-item-space clearfix">
                                <div className="edgtf-bli-inner">
                                  <div className="edgtf-bli-content">
                                    <h6 className="entry-title edgtf-post-title">
                                      <Link to="#" title="Urban Vibe">
                                        Urban Vibe{' '}
                                      </Link>
                                    </h6>
                                    <div className="edgtf-post-info-date entry-date published updated">
                                      <Link to="#">February 20, 2018 </Link>
                                      {/* <meta
																	content="UserComments: 0"> */}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edgtf-column-content edgtf-grid-col-3">
                      <div
                        id="text-3"
                        className="widget edgtf-footer-column-3 widget_text"
                      >
                        <h5 className="edgtf-widget-title">CONTACT US</h5>
                        <div className="textwidget">
                          <p>
                            <Link to="mailto:contact@partypass.com">
                              contact@partypass.com
                            </Link>
                            <br />
                            <Link to="tel:1-562-867-5309">1-562-867-5309</Link>
                          </p>
                        </div>
                      </div>
                      <div
                        id="text-11"
                        className="widget edgtf-footer-column-3 widget_text"
                      >
                        <div className="textwidget">
                          <p>
                            <Link to="https://www.google.rs/maps/place/Charging+Bull/@40.7055242,-74.0133806,20z/data=!4m5!3m4!1s0x0:0xc7db393ae1eff521!8m2!3d40.7055537!4d-74.0134436">
                              Broadway &amp; Morris St, New York
                            </Link>
                            <br />
                            <Link to="https://www.google.rs/maps/place/Autoklub+Vsetin+Czechia/@28.0903625,-80.5700215,17z/data=!3m1!4b1!4m5!3m4!1s0x88de116ac5f97e1b:0xbcd6048a3d229c61!8m2!3d28.0903625!4d-80.5678328">
                              123 6th St. Melbourne, FL
                            </Link>
                          </p>
                        </div>
                      </div>
                      <div
                        id="text-12"
                        className="widget edgtf-footer-column-3 widget_text"
                      >
                        <div className="textwidget">
                          <p>
                            New York City
                            <br />
                            State of New York
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="edgtf-column-content edgtf-grid-col-3">
                      <div
                        id="custom_html-2"
                        className="widget_text widget edgtf-footer-column-4 widget_custom_html"
                      >
                        <h5 className="edgtf-widget-title">FOLLOW US</h5>
                        <div className="textwidget custom-html-widget">
                          For all the latest news and updates, follow us on
                          Twitter: <Link to="#">@partypass.com</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="edgtf-footer-bottom-holder">
                <div className="edgtf-footer-bottom-inner edgtf-grid">
                  <div className="edgtf-grid-row ">
                    <div className="edgtf-grid-col-4">
                      <div
                        id="media_image-2"
                        className="widget edgtf-footer-bottom-column-1 widget_media_image"
                      >
                        <div className="edgtf-logo-wrapper">
                          <Link to="#">
                            <img
                              width="40"
                              height="40"
                              alt="logo"
                              src="../images/emblem.png"
                            />
                            PartyPass
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="edgtf-grid-col-4">
                      <div className="widget edgtf-social-icons-group-widget text-align-center">
                        {' '}
                        <Link
                          className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                          style={{ margin: '6px 8px 0 8px' }}
                          to="https://www.instagram.com/qodeinteractive/"
                          target="_blank"
                        >
                          <span className="edgtf-social-icon-widget social_instagram_circle"></span>{' '}
                        </Link>
                        <Link
                          className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                          style={{ margin: '6px 8px 0 8px' }}
                          to="https://www.facebook.com/QodeInteractive/"
                          target="_blank"
                        >
                          <span className="edgtf-social-icon-widget social_facebook_circle"></span>{' '}
                        </Link>
                        <Link
                          className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                          style={{ margin: '6px 8px 0 8px' }}
                          to="https://plus.google.com/"
                          target="_blank"
                        >
                          <span className="edgtf-social-icon-widget social_googleplus_circle"></span>{' '}
                        </Link>
                        <Link
                          className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                          style={{ margin: '6px 8px 0 8px' }}
                          to="https://twitter.com/QodeInteractive"
                          target="_blank"
                        >
                          <span className="edgtf-social-icon-widget social_twitter_circle"></span>{' '}
                        </Link>
                        <Link
                          className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                          style={{ margin: '6px 8px 0 8px' }}
                          to="https://www.youtube.com/QodeInteractiveVideos"
                          target="_blank"
                        >
                          <span className="edgtf-social-icon-widget social_youtube_circle"></span>{' '}
                        </Link>
                        <Link
                          className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                          style={{ margin: '6px 8px 0 8px' }}
                          to="https://vimeo.com/"
                          target="_blank"
                        >
                          <span className="edgtf-social-icon-widget social_vimeo_circle"></span>{' '}
                        </Link>
                      </div>
                    </div>
                    <div className="edgtf-grid-col-4">
                      <div className="widget edgtf-separator-widget">
                        <div className="edgtf-separator-holder clearfix  edgtf-separator-center edgtf-separator-normal">
                          <div
                            className="edgtf-separator"
                            style={{
                              borderColor: ' rgba(255, 255, 255, 0)',
                              borderStyle: 'solid',
                              marginTop: '-8px',
                            }}
                          ></div>
                        </div>
                      </div>
                      <div
                        id="text-6"
                        className="widget edgtf-footer-bottom-column-3 widget_text"
                      >
                        <div className="textwidget">
                          <p>
                            © 2024{' '}
                            <Link
                              to="https://qodeinteractive.com/"
                              target="_blank"
                              rel="noopener"
                            >
                              PartyPass
                            </Link>
                            , All Rights Reserved
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
