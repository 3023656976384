import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NotFound from '../Components/NotFound';
import PublicRoute from '../Utils/Dashboard/PublicRoute';
import PrivateRoute from '../Utils/Dashboard/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { publicRoutes } from './PublicRoutes';
import { privateRoutes } from './PrivateRoutes';

const AppRoutes = () => (
  <Router>
    <Routes>
      {publicRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            <PublicRoute
              element={route.element}
              redirectTo={route.redirectTo}
            />
          }
        />
      ))}
      {privateRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={<PrivateRoute element={route.element} />}
        />
      ))}
      <Route path="*" element={<NotFound />} />
    </Routes>
    <ToastContainer />
  </Router>
);
export default AppRoutes;
