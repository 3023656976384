export const GENERAL_TYPE = 1;
export const USER_PROFILE_PICTURE_TYPE = 2;
export const ORGANIZATION_LOGO_TYPE = 3;
export const ORGANIZATION_BANNER_TYPE = 4;
export const ORGANIZATION_GALLERY_TYPE = 5;
export const EVENT_BANNER_TYPE = 6;
export const EVENT_GALLERY_TYPE = 7;
export const EVENT_POSTER_TYPE = 8;
export const EVENT_VIDEO_TYPE = 9;
export const ORGANIZATION_VIDEO_TYPE = 10;
