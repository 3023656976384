import React from 'react';
import Layout from './Components/Layout';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  return (
    <>
      <Layout>
        <div className="page-breadcrumb d-flex align-items-center mb-3">
          <div className="font-22">Home</div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 g-3">
          <div className="col">
            <div className="card radius-10 bg-gradient-royal">
              <div className="card-body text-center px-5">
                <p className="font-22">
                  Start by crafting your branded empire, then add your events.
                </p>
                <Link
                  to="/create-org"
                  className="btn btn-lg btn-outline-warning mt-3 px-5 radius-10"
                >
                  BUILD YOUR ORGANIZATION
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card radius-10 bg-gradient-royal">
              <div className="card-body text-center px-5">
                <p className="font-22">
                  Go live in seconds. Build your organization anytime after.
                </p>
                <Link
                  to="/create-events"
                  className="btn btn-lg btn-outline-info mt-3 px-5 radius-10"
                >
                  LAUNCH AN EVENT
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="card radius-10">
          <div className="card-body">
            <div className="row row-cols-2 row-cols-md-4 g-3">
              <div className="col">
                <div className="card radius-10 shadow-none bg-light-success mb-0">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 text-success">
                        <ion-icon
                          name="cube"
                          role="img"
                          aria-label="card sharp"
                        ></ion-icon>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-4">
                      <div className="">
                        <p className="mb-1 text-success">My Organizations</p>
                        <h4 className="mb-0 text-success">5</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card radius-10 shadow-none bg-light-info mb-0">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 text-info">
                        <ion-icon
                          name="invert-mode-outline"
                          role="img"
                          aria-label="bag check sharp"
                        ></ion-icon>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-4">
                      <div className="">
                        <p className="mb-1 text-info">Upcoming Events</p>
                        <h4 className="mb-0 text-info">12</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card radius-10 shadow-none bg-light-danger mb-0">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 text-danger">
                        <ion-icon
                          name="arrow-undo-outline"
                          role="img"
                          aria-label="gift sharp"
                        ></ion-icon>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-4">
                      <div className="">
                        <p className="mb-1 text-danger">Past Events</p>
                        <h4 className="mb-0 text-danger">8</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card radius-10 shadow-none bg-light-warning mb-0">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 text-warning">
                        <ion-icon
                          name="create-outline"
                          role="img"
                          aria-label="shield half sharp"
                        ></ion-icon>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-4">
                      <div className="">
                        <p className="mb-1 text-warning">Draft Events</p>
                        <h4 className="mb-0 text-warning">2</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Dashboard;
