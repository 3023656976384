import ProfileUpdate from '../Seller/Pages/Auth/Profile/ProfileUpdate';
import Dashboard from '../Seller/Pages/Dashboard/Dashboard';
import Listing from '../Seller/Pages/Dashboard/Events/Listing';
import OrgDashboard from '../Seller/Pages/Dashboard/Organization/Dashboard/OrgDashboard';
import EditPage from '../Seller/Pages/Dashboard/Organization/Dashboard/Edit/EditPage';
import EditEventsUser from '../Seller/Pages/Dashboard/Organization/Dashboard/Events/EditEventsUser.jsx';
import CreateOrganization from '../Seller/Pages/Dashboard/CreateOrganization.jsx';
import OrganiziationListing from '../Seller/Pages/Dashboard/OrganiziationListing.jsx';
import EventListing from '../Seller/Pages/Dashboard/Organization/Dashboard/Events/EventListing.jsx';
import CreateEvent from '../Seller/Pages/Dashboard/Events/CreateEvent.jsx';
import EventsCreate from '../Seller/Pages/Dashboard/Organization/Dashboard/Events/EventsCreate.jsx';
import PreviewMode from '../Seller/Pages/Dashboard/Organization/Dashboard/Events/PreviewMode.jsx';
// Private Routes
export const privateRoutes = [
  { path: 'dashboard', element: <Dashboard /> },
  { path: 'profile-update', element: <ProfileUpdate /> },
  { path: 'listing', element: <Listing /> },
  { path: 'organiziations', element: <OrganiziationListing /> },
  { path: 'create-org', element: <CreateOrganization /> },
  { path: 'org-dashboard', element: <OrgDashboard /> },
  { path: 'create-events', element: <CreateEvent /> },
  { path: 'edit-page', element: <EditPage /> },
  { path: 'events', element: <EventListing /> },
  { path: 'edit-events-user', element: <EditEventsUser /> },
  { path: 'org-event-create', element: <EventsCreate /> },
  { path: 'preview-mode', element: <PreviewMode /> },
];
