import OrgLayout from '../Components/OrgLayout';
import CardEvent from '../Components/CardEvent';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../../../../Utils/axiosInstance';
import { ORGANIZATION_EVENT_ENDPOINT } from '../../../../../../Utils/Endpoints';
import Logger from '../../../../../../Utils/Logger';
import { getObject } from '../../../../../../Utils/LocalStorage';
import moment from 'moment';
import NotFoundPage from '../../../Components/NotFoundPage';
import { Link } from 'react-router-dom';
import Pagination from '../../../Components/Pagination';

const EventListing = () => {
  const [upcomingEventList, setUpcomingEventList] = useState([]);
  const [pastEventList, setPastEventList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const organisation = getObject('selectedOrganization');

  // Upcoming Pagination
  const [itemsPerPageUpcoming] = useState(10);
  const [totalItemsUpcoming, setTotalItemsUpcoming] = useState(0);
  const [currentPageUpcoming, setCurrentPageUpcoming] = useState(1);

  const handlePageChangeUpcoming = (pageNumber) => {
    setCurrentPageUpcoming(pageNumber);
  };

  // Past Pagination
  const [itemsPerPagePast] = useState(10);
  const [totalItemsPast, setTotalItemsPast] = useState(0);
  const [currentPagePast, setCurrentPagePast] = useState(1);

  const handlePageChangePast = (pageNumber) => {
    setCurrentPagePast(pageNumber);
  };

  useEffect(() => {
    fetchUpcomingEvent();
  }, [currentPageUpcoming]);

  useEffect(() => {
    fetchPastEvent();
  }, [currentPagePast]);

  const fetchUpcomingEvent = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        ORGANIZATION_EVENT_ENDPOINT +
          `${organisation.id}/events?event_start_from=${moment().unix()}&page=${currentPageUpcoming}&per_page=${itemsPerPageUpcoming}`
      );
      if (response.data.code === 200) {
        setUpcomingEventList(response.data.data.data);
        setTotalItemsUpcoming(response.data.data.total);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPastEvent = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        ORGANIZATION_EVENT_ENDPOINT +
          `${organisation.id}/events?event_start_to=${moment().unix()}&page=${currentPagePast}&per_page=${itemsPerPagePast}`
      );
      if (response.data.code === 200) {
        setPastEventList(response.data.data.data);
        setTotalItemsPast(response.data.data.total);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <OrgLayout>
        <div className="row d-none">
          <div className="col col-lg-12 mx-auto">
            <ul className="mb-3 nav nav-tabs nav-warning" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  data-bs-toggle="pill"
                  href="#warning-pills-home"
                  role="tab"
                  aria-selected="false"
                  tabIndex="-1"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="tab-title">Upcoming</div>
                  </div>
                </a>
              </li>
              <li className="nav-item d-none" role="presentation">
                <a
                  className="nav-link "
                  data-bs-toggle="pill"
                  href="#warning-pills-profile"
                  role="tab"
                  aria-selected="true"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="tab-title">Past Events</div>
                  </div>
                </a>
              </li>
              <li className="nav-item d-none" role="presentation">
                <a
                  className="nav-link"
                  data-bs-toggle="pill"
                  href="#warning-pills-contact"
                  role="tab"
                  aria-selected="false"
                  tabIndex="-1"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="tab-title">Drafts</div>
                  </div>
                </a>
              </li>
            </ul>
            <div className="card">
              <h6>Upcoming Events</h6>
              <hr />

              <div className="tab-content">
                <div
                  className="tab-pane fade active show"
                  id="warning-pills-home"
                  role="tabpanel"
                >
                  {!isLoading && upcomingEventList?.length > 0 && (
                    <div className="row row-cols-12 col-md-12 row-cols-lg-4">
                      {upcomingEventList?.map((item, key) => (
                        <CardEvent
                          key={key}
                          event={item}
                          userImage={
                            item?.banner?.fullUrl
                              ? item?.banner?.fullUrl
                              : '../images/h1-img-2.jpg'
                          }
                          badges="chayal"
                          admin="Admin"
                          name={item?.name ? item?.name : 'Event'}
                          titleFirst={
                            item?.location?.addressString
                              ? item?.location?.addressString
                              : ''
                          }
                          titleSecond={moment
                            .unix(item?.start_date_time_unix)
                            .format('MMMM D, YYYY hh:mm A')}
                        />
                      ))}
                    </div>
                  )}
                  {!isLoading && upcomingEventList?.length === 0 && (
                    <NotFoundPage />
                  )}
                </div>
                <div
                  className="tab-pane fade"
                  id="warning-pills-profile"
                  role="tabpanel"
                >
                  <div className="row row-cols-12 row-cols-lg-6 row-cols-xl-4 g-3 mb-4">
                    <CardEvent
                      userImage="../images/06.png"
                      badges="chayal"
                      admin="Admin"
                      name="Ajay"
                      titleFirst="Aibania, Albania"
                      titleSecond="October 16, 2024 12:07 PM"
                    />
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="warning-pills-contact"
                  role="tabpanel"
                >
                  <div className="row row-cols-12 row-cols-lg-6 row-cols-xl-4 g-3 mb-4">
                    <CardEvent
                      userImage="../images/06.png"
                      badges="chayal"
                      admin="Admin"
                      name="Ajay"
                      titleFirst="Aibania, Albania"
                      titleSecond="October 16, 2024 12:07 PM"
                    />
                    <CardEvent
                      userImage="../images/06.png"
                      badges="chayal"
                      admin="Admin"
                      name="Ajay"
                      titleFirst="Aibania, Albania"
                      titleSecond="October 16, 2024 12:07 PM"
                    />
                    <CardEvent
                      userImage="../images/06.png"
                      badges="chayal"
                      admin="Admin"
                      name="Ajay"
                      titleFirst="Aibania, Albania"
                      titleSecond="October 16, 2024 12:07 PM"
                    />
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="warning-pills-contact"
                  role="tabpanel"
                >
                  <div className="row row-cols-1 row-cols-lg-2">
                    <CardEvent
                      userImage="../images/06.png"
                      badges="chayal"
                      admin="Admin"
                      name="Ajay"
                      titleFirst="Aibania, Albania"
                      titleSecond="October 16, 2024 12:07 PM"
                    />
                    <CardEvent
                      userImage="../images/06.png"
                      badges="chayal"
                      admin="Admin"
                      name="Ajay"
                      titleFirst="Aibania, Albania"
                      titleSecond="October 16, 2024 12:07 PM"
                    />
                    <CardEvent
                      userImage="../images/06.png"
                      badges="chayal"
                      admin="Admin"
                      name="Ajay"
                      titleFirst="Aibania, Albania"
                      titleSecond="October 16, 2024 12:07 PM"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card p-3">
          <h6>Upcoming Events</h6>
          <hr />
          <div>
            <div className="card bg-secondary-dark mb-0 border-1 hover-border-info d-none">
              <img
                src="https://media.chillzapp.com/uploads/pictures/a60aed8b2d08aa96c8ed77dbbcc2d6c2.jpeg"
                className="card-img-top empty"
                alt="..."
              />
              <div className="card-body border-bottom h-100">
                <h5 className="card-title">AFTER PROM</h5>
                <p className="card-text">New York, NY</p>
              </div>

              <div className="card-body border-top">
                <Link
                  to="/org-dashboard"
                  className="btn btn-sm btn-outline-warning me-2"
                >
                  Edit Detail
                </Link>
                <span className="float-md-end badge bg-primary">Draft</span>
              </div>
            </div>
            {!isLoading && upcomingEventList?.length > 0 && (
              <>
                <div className="row row-cols-1 row-cols-md-3 row-cols-xl-4 g-3">
                  {upcomingEventList?.map((item, key) => (
                    <CardEvent
                      key={key}
                      event={item}
                      userImage={
                        item?.banner?.fullUrl
                          ? item?.banner?.fullUrl
                          : '../images/thumbnail.png'
                      }
                      badges="chayal"
                      admin="Admin"
                      name={item?.name ? item?.name : 'Event'}
                      titleFirst={
                        item?.location?.addressString
                          ? item?.location?.addressString
                          : ''
                      }
                      titleSecond={moment
                        .unix(item?.start_date_time_unix)
                        .format('MMMM D, YYYY hh:mm A')}
                    />
                  ))}
                </div>
                {totalItemsUpcoming > 10 && (
                  <Pagination
                    itemsPerPage={itemsPerPageUpcoming}
                    totalItems={totalItemsUpcoming}
                    currentPage={currentPageUpcoming}
                    onPageChange={handlePageChangeUpcoming}
                  />
                )}
              </>
            )}
            {!isLoading && upcomingEventList?.length === 0 && <NotFoundPage />}
          </div>
        </div>
        <div className="card p-3">
          <h6>Past Events</h6>
          <hr />
          {!isLoading && pastEventList?.length > 0 && (
            <>
              <div className="row row-cols-1 row-cols-md-3 row-cols-xl-4 g-3">
                {pastEventList?.map((item, key) => (
                  <CardEvent
                    key={key}
                    event={item}
                    userImage={
                      item?.banner?.fullUrl
                        ? item?.banner?.fullUrl
                        : '../images/thumbnail.png'
                    }
                    badges="chayal"
                    admin="Admin"
                    name={item?.name ? item?.name : 'Event'}
                    titleFirst={
                      item?.location?.addressString
                        ? item?.location?.addressString
                        : ''
                    }
                    titleSecond={moment
                      .unix(item?.start_date_time_unix)
                      .format('MMMM D, YYYY hh:mm A')}
                  />
                ))}
              </div>
              {totalItemsPast > 10 && (
                <Pagination
                  itemsPerPage={itemsPerPagePast}
                  totalItems={totalItemsPast}
                  currentPage={currentPagePast}
                  onPageChange={handlePageChangePast}
                />
              )}
            </>
          )}

          {!isLoading && pastEventList?.length === 0 && <NotFoundPage />}
        </div>
      </OrgLayout>
    </>
  );
};

export default EventListing;
