import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const EventCard = (props) => {
  return (
    <>
      <div className="item c-t">
        <Link to="/home-card-preview">
          <img src={props.imageUser} alt="" />
          <h5>{props.heading}</h5>
          <p>{props.text}</p>
        </Link>
      </div>
    </>
  );
};
EventCard.propTypes = {
  imageUser: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
};

export default EventCard;
