import React, { useEffect, useRef, useState } from 'react';
import Stepper from 'bs-stepper';
import axiosInstance from '../../../../../../../Utils/axiosInstance';
import Logger from '../../../../../../../Utils/Logger';
import { EVENT_TICKET_ENDPOINT } from '../../../../../../../Utils/Endpoints';
import PropTypes from 'prop-types';
import Toast from '../../../../../../../Utils/Toast';

const Setting = ({ event }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [settingData, setSettingData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const formRef1 = useRef(null);
  const formRef2 = useRef(null);
  const formRef3 = useRef(null);
  const stepperRef = useRef(null);

  useEffect(() => {
    if (stepperRef.current) {
      // Initialize your stepper here
      new Stepper(stepperRef.current);
    }
  }, [stepperRef]);

  useEffect(() => {
    fetchSettingData();
  }, []);

  const fetchSettingData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        EVENT_TICKET_ENDPOINT + `${event?.id}/list`
      );
      if (response.data.code === 200) {
        setSettingData(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  function extractSettings(input) {
    const output = {};

    // Access the settings of the "PAGE_INTEGRATION" group
    const pageIntegrationSettings = input.PAGE_INTEGRATION.settings;

    // Access the settings of the "OTHERS" group
    const otherSettings = input.OTHERS.settings;

    // Extract PAGE_INTEGRATION settings
    Object.keys(pageIntegrationSettings).forEach((key) => {
      output[key] = pageIntegrationSettings[key].value;
    });

    // Extract FEE settings
    Object.keys(otherSettings).forEach((key) => {
      output[key] = otherSettings[key].value;
    });

    return output;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError('');
    setIsLoading(true);
    try {
      const requestObject = {
        settings: extractSettings(settingData),
      };
      const response = await axiosInstance.post(
        EVENT_TICKET_ENDPOINT + `${event?.id}/setup`,
        requestObject
      );
      if (response.data.code !== 200) {
        setGeneralError(response.data.message);
        return;
      }
      setCurrentStep(1);
      Toast.success(response.data.message, {
        autoClose: 2000,
      });
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Integration
  const handleInputChange = (key) => (e) => {
    const newValue = e.target.value;

    // Update settingData state
    setSettingData((prevState) => ({
      ...prevState,
      PAGE_INTEGRATION: {
        ...prevState.PAGE_INTEGRATION,
        settings: {
          ...prevState.PAGE_INTEGRATION.settings,
          [key]: {
            ...prevState.PAGE_INTEGRATION.settings[key],
            value: newValue, // Update the value
          },
        },
      },
    }));
  };

  // Others
  const handleChange = (key) => (e) => {
    const newValue = e.target.value;

    // Update settingData state
    setSettingData((prevState) => ({
      ...prevState,
      OTHERS: {
        ...prevState.OTHERS,
        settings: {
          ...prevState.OTHERS.settings,
          [key]: {
            ...prevState.OTHERS.settings[key],
            value: newValue,
          },
        },
      },
    }));
  };

  return (
    <>
      <div className="">
        <div className="page-breadcrumb  mb-3">
          <div className="font-22">Settings</div>
          <small>Unless changed, settings are inherited from ajay</small>
        </div>
        <div id="stepper1" className="bs-stepper" ref={stepperRef}>
          <div className="card">
            <div className="card-header pe-md-5">
              <div
                className="d-lg-flex flex-lg-row align-items-lg-center justify-content-lg-between"
                role="tablist"
              >
                <div
                  className={`step ${currentStep === 1 ? 'active' : ''}`}
                  data-target="#test-l-1"
                >
                  <div
                    className="step-trigger"
                    role="tab"
                    id="stepper1trigger1"
                    aria-controls="test-l-1"
                  >
                    <div
                      className={`bs-stepper-circle ${currentStep === 1 ? 't-a' : 't-a-i'}`}
                    >
                      1
                    </div>
                    <div className="">
                      <h5 className="mb-0 steper-title">Integrations</h5>
                      <p className="mb-0 steper-sub-title">Step 1</p>
                    </div>
                  </div>
                </div>
                <div className="bs-stepper-line"></div>
                <div
                  className={`step ${currentStep === 2 ? 'active' : ''}`}
                  data-target="#test-l-2"
                >
                  <div
                    className="step-trigger"
                    role="tab"
                    id="stepper1trigger2"
                    aria-controls="test-l-2"
                  >
                    <div
                      className={`bs-stepper-circle ${currentStep === 2 ? 't-a' : 't-a-i'}`}
                    >
                      2
                    </div>
                    <div className="">
                      <h5 className="mb-0 steper-title">Service Fees</h5>
                      <p className="mb-0 steper-sub-title">Step 2</p>
                    </div>
                  </div>
                </div>
                <div className="bs-stepper-line"></div>
                <div
                  className={`step ${currentStep === 3 ? 'active' : ''}`}
                  data-target="#test-l-3"
                >
                  <div
                    className="step-trigger"
                    role="tab"
                    id="stepper1trigger3"
                    aria-controls="test-l-3"
                  >
                    <div
                      className={`bs-stepper-circle ${currentStep === 3 ? 't-a' : 't-a-i'}`}
                    >
                      3
                    </div>
                    <div className="">
                      <h5 className="mb-0 steper-title">Other</h5>
                      <p className="mb-0 steper-sub-title">Step 3</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="bs-stepper-content">
                <div
                  id="test-l-1"
                  role="tabpanel"
                  className={`bs-stepper-pane ${currentStep === 1 ? 'active' : ''}`}
                  aria-labelledby="stepper1trigger1"
                >
                  <form ref={formRef1} noValidate>
                    <div className="row g-3">
                      {settingData &&
                        Object.entries(
                          settingData?.PAGE_INTEGRATION?.settings
                        ).map(([key, { name, value, type }], index) => (
                          <div className="col-lg-6 col-12" key={index}>
                            <label
                              htmlFor={key.toLowerCase()}
                              className="form-label"
                            >
                              {name}
                            </label>
                            <input
                              type={type}
                              className="form-control"
                              id={key.toLowerCase()}
                              defaultValue={value || ''}
                              onChange={handleInputChange(key)}
                            />
                          </div>
                        ))}

                      {/* <div className="col-lg-6 col-12">
                        <label htmlFor="facebook" className="form-label">
                          Facebook Pixel
                        </label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="col-lg-6 col-12">
                        <label htmlFor="tik-tok" className="form-label">
                          Tiktok Pixel
                        </label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="col-lg-6 col-12">
                        <label htmlFor="google-manager" className="form-label">
                          Google Tag Manager
                        </label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="col-lg-6 col-12">
                        <label htmlFor="snapchat" className="form-label">
                          Snapchat
                        </label>
                        <input type="text" className="form-control" />
                      </div> */}
                      <div className="col-12 mt-4">
                        <button
                          className="btn btn-outline-warning px-4"
                          onClick={handleNextStep}
                          type="button"
                        >
                          Next
                          <i className="bx bx-right-arrow-alt ms-2"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  id="test-l-2"
                  role="tabpanel"
                  className={`bs-stepper-pane ${currentStep === 2 ? 'active' : ''}`}
                  aria-labelledby="stepper1trigger2"
                >
                  <form ref={formRef2} noValidate>
                    <div className="row g-3">
                      {settingData &&
                        Object.entries(settingData?.FEE?.settings).map(
                          ([key, { name, value, type }], index) => (
                            <div className="col-12 col-lg-6" key={index}>
                              <label
                                htmlFor={key.toLowerCase()}
                                className="form-label"
                              >
                                {name}
                              </label>

                              {type === 'select' && (
                                <select
                                  className="form-select"
                                  id={key.toLowerCase()}
                                  name={key.toLowerCase()}
                                  disabled
                                >
                                  <option value={value}>{value}</option>
                                </select>
                              )}

                              {type === 'toggle' && (
                                <div>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultChecked={value || ''}
                                    disabled
                                  />
                                </div>
                              )}

                              {type === 'number' && (
                                <div className="input-group flex-nowrap">
                                  <span
                                    className="input-group-text"
                                    id="addon-wrapping"
                                  >
                                    USD
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control text-center"
                                    placeholder={value || ''}
                                    defaultValue={value || ''}
                                    disabled
                                  />
                                </div>
                              )}

                              {type !== 'number' &&
                                type !== 'select' &&
                                type !== 'toggle' && (
                                  <input
                                    type={type}
                                    className="form-control"
                                    id={key.toLowerCase()}
                                    defaultValue={value || ''}
                                    disabled
                                  />
                                )}
                            </div>
                          )
                        )}
                      <div className="col-12 col-lg-3 d-none">
                        <div className="input-group flex-nowrap">
                          {' '}
                          <span
                            className="input-group-text"
                            id="addon-wrapping"
                          >
                            %
                          </span>
                          <input
                            type="text"
                            className="form-control text-center"
                            placeholder="7"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-12 mt-4">
                        <div className="d-flex align-items-center gap-3">
                          <button
                            className="btn btn-outline-secondary px-4"
                            onClick={handlePreviousStep}
                            type="button"
                          >
                            <i className="bx bx-left-arrow-alt me-2"></i>
                            Previous
                          </button>
                          <button
                            className="btn btn-outline-warning px-4"
                            onClick={handleNextStep}
                            type="button"
                          >
                            Next
                            <i className="bx bx-right-arrow-alt ms-2"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  id="test-l-3"
                  role="tabpanel"
                  className={`bs-stepper-pane ${currentStep === 3 ? 'active' : ''}`}
                  aria-labelledby="stepper1trigger3"
                >
                  <form ref={formRef3} noValidate>
                    <div className="row g-3 ">
                      {settingData &&
                        Object.entries(settingData?.OTHERS?.settings).map(
                          ([key, { name, value, type }], index) => (
                            <div className="col-12 col-lg-6" key={index}>
                              <label
                                htmlFor={key.toLowerCase()}
                                className="form-label"
                              >
                                {name}
                              </label>
                              {type === 'editor' ? (
                                <textarea
                                  className="form-control"
                                  id={key.toLowerCase()}
                                  defaultValue={value || ''}
                                  onChange={handleChange(key)}
                                ></textarea>
                              ) : (
                                <input
                                  type={type}
                                  className="form-control"
                                  id={key.toLowerCase()}
                                  defaultValue={value || ''}
                                  onChange={handleChange(key)}
                                />
                              )}
                            </div>
                          )
                        )}
                      <div className="col-12">
                        <div className="d-flex align-items-center gap-3">
                          <button
                            className="btn btn-outline-secondary px-4"
                            onClick={handlePreviousStep}
                            type="button"
                          >
                            <i className="bx bx-left-arrow-alt me-2"></i>
                            Previous
                          </button>
                          <button
                            className="btn bg-gradient-branding text-dark px-4"
                            onClick={handleSubmit}
                            type="button"
                            disabled={isLoading}
                          >
                            {isLoading ? 'Loading...' : 'Save'}
                            <i className="bx bx-right-arrow-alt ms-2"></i>
                          </button>
                        </div>
                        {generalError && (
                          <div className="error text-center">
                            {generalError}
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Setting.propTypes = {
  event: PropTypes.object,
};
export default Setting;
