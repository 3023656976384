import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const SideNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(location.pathname);

  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <aside className="sidebar-wrapper" data-simplebar="true">
        <div
          className="sidebar-header "
          onClick={() => navigate('/organiziations')}
        >
          <div>
            <img
              src="../images/logo-icon-2.png"
              className="logo-icon"
              alt="logo icon"
            />
          </div>
          <div>
            <h4 className="logo-text">PARTYPASS</h4>
          </div>
        </div>
        <ul className="metismenu" id="menu">
          <li className={activeItem === '/org-dashboard' ? 'mm-active' : ''}>
            <Link to="/org-dashboard">
              <div className="parent-icon">
                <ion-icon name="home-outline"></ion-icon>
              </div>
              <div className="menu-title">Home</div>
            </Link>
          </li>
          <li className={activeItem === '/events' ? 'mm-active' : ''}>
            <Link to="/events">
              <div className="parent-icon">
                <ion-icon name="invert-mode-outline"></ion-icon>
              </div>
              <div className="menu-title">Events</div>
            </Link>
          </li>
          <li className={activeItem === '/edit-page' ? 'mm-active' : ''}>
            <Link to="/edit-page">
              <div className="parent-icon">
                <ion-icon name="create-outline"></ion-icon>
              </div>
              <div className="menu-title">Edit</div>
            </Link>
          </li>
          <li className="d-none ">
            <Link to="#">
              <div className="parent-icon">
                <ion-icon name="people-outline"></ion-icon>
              </div>
              <div className="menu-title">Teams</div>
            </Link>
          </li>
          <li className="border-top mt-5 ">
            <Link to="/organiziations" className="text-primary">
              <div className="parent-icon">
                <ion-icon name="swap-horizontal-outline"></ion-icon>
              </div>
              <div className="menu-title">Change Organization</div>
            </Link>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default SideNav;
