import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const SideNav = () => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(location.pathname);

  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <aside className="sidebar-wrapper" data-simplebar="true">
        <div className="sidebar-header">
          <div>
            <img
              src="../images/logo-icon-2.png"
              className="logo-icon"
              alt="logo icon"
            />
          </div>
          <div>
            <h4 className="logo-text">PARTYPASS</h4>
          </div>
        </div>
        {/* navigation */}
        <ul className="metismenu" id="menu">
          <li className={activeItem === '/dashboard' ? 'mm-active' : ''}>
            <Link to="/dashboard">
              <div className="parent-icon">
                <ion-icon name="home-outline"></ion-icon>
              </div>
              <div className="menu-title">Home</div>
            </Link>
          </li>
          <li className={activeItem === '/organiziations' ? 'mm-active' : ''}>
            <Link to="/organiziations">
              <div className="parent-icon">
                <ion-icon name="grid-outline"></ion-icon>
              </div>
              <div className="menu-title">My Organizations</div>
            </Link>
          </li>
          <li className={activeItem === '/listing' ? 'mm-active' : ''}>
            <Link to="/listing">
              <div className="parent-icon">
                <ion-icon name="invert-mode-outline"></ion-icon>
              </div>
              <div className="menu-title">Events</div>
            </Link>
          </li>
        </ul>
        {/* end navigation */}
      </aside>
    </>
  );
};

export default SideNav;
