import Layout from '../Components/Layout';
import CreateEventForm from './CreateEventForm';

const CreateEvent = () => {
  return (
    <Layout>
      <CreateEventForm />
    </Layout>
  );
};

export default CreateEvent;
