const AddGuest = () => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-6 col-lg-6 col-xl-6">
            <label htmlFor="Name" className="form-label">
              Name
            </label>
            <input type="text" className="form-control" />
          </div>
          <div className="col-6 col-lg-6 col-xl-6">
            <label htmlFor="lastName" className="form-label">
              Last Name
            </label>
            <input type="text" className="form-control" />
          </div>
          <div className="col-6 col-lg-6 col-xl-6 mt-2">
            <label htmlFor="idNumber" className="form-label">
              ID Number
            </label>
            <input type="text" className="form-control" />
          </div>
          <div className="col-6 col-lg-6 col-xl-6 mt-2">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input type="email" className="form-control" />
          </div>
          <div className="col-6 col-lg-6 col-xl-6 mt-2">
            <label htmlFor="facebook" className="form-label">
              Facebook
            </label>
            <input type="url" className="form-control" />
          </div>
          <div className="col-6 col-lg-6 col-xl-6 mt-2">
            <label htmlFor="instagram" className="form-label">
              Instagram
            </label>
            <input type="url" className="form-control" />
          </div>
          <div className="col-6 col-lg-6 col-xl-6 mt-2">
            <label htmlFor="phone" className="form-label">
              Phone Number
            </label>
            <input type="phone" className="form-control" />
          </div>
          <div className="col-6 col-lg-6 col-xl-6 mt-2">
            <label htmlFor="gender" className="form-label">
              Gender
            </label>
            <select name="" id="" className="form-select">
              <option value="Male">Male</option>
              <option value="Woman">Woman</option>
              <option value="other">other</option>
              <option value="Prefer not to say">Prefer not to say</option>
            </select>
          </div>
          <div className="col-12 mt-4">
            <div className="d-flex align-items-center gap-3">
              <button className="btn btn-outline-warning px-4" type="button">
                Save
              </button>
            </div>
          </div>
        </div>
        {/* <a className="btn btn-outline-warning mt-3" href="#">
          Save
        </a> */}
      </div>
    </div>
  );
};

export default AddGuest;
